import React, { useState } from 'react';
import axios from 'axios';
import ContentHeader from '../Atoms/contentHeader';
import Loader from './loader';
import { useDropzone } from 'react-dropzone';
import { drawGridOnImage } from './utils';
import { useNavigate } from 'react-router-dom';
import { XCircleIcon } from '@heroicons/react/24/solid';

const directusAPI = axios.create();

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

function HeatmapsAnalysis() {
  const [url, setUrl] = useState('');
  const [imageSrc, setImageSrc] = useState({ desktop: '', mobile: '' });
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [inputMethod, setInputMethod] = useState(null);
  const [apiDelay, setApiDelay] = useState(10);
  const [heatmapId, setHeatmapId] = useState(null);

  const navigate = useNavigate();

  const normalizeUrl = (inputUrl) => {
    if (!inputUrl.match(/^[a-zA-Z]+:\/\//)) {
      return 'https://' + inputUrl;
    } else if (inputUrl.startsWith('http://')) {
      return inputUrl.replace('http://', 'https://');
    }
    return inputUrl;
  };

  const extractDomain = (url) => {
    const domain = url.replace(/^https?:\/\//, '').replace(/\/$/, '').split('/')[0];
    return domain.replace(/\.(com|org|net|io|etc)$/, '');
  };

  const handleSearchChange = (event) => {
    setUrl(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    setInputMethod('search');
    const normalizedUrl = normalizeUrl(url);
    await captureScreenshots(normalizedUrl);
  };

  const captureScreenshots = async (normalizedUrl) => {
    try {
      setIsUploading(true);
      const [desktopScreenshot, mobileScreenshot] = await Promise.all([
        captureScreenshot(normalizedUrl, 'desktop'),
        captureScreenshot(normalizedUrl, 'mobile')
      ]);

      const title = extractDomain(url);
      const newHeatmapId = await uploadOriginalAndGridImages(desktopScreenshot, mobileScreenshot, title);
      setHeatmapId(newHeatmapId);
      setSubmitStatus('success');
      setShowTooltip(true);
    } catch (error) {
      console.error('Failed to capture screenshots:', error);
      setSubmitStatus('error');
    } finally {
      setIsUploading(false);
    }
  };

  const captureScreenshot = async (url, device) => {
    const apiFlashEndpoint = `https://api.apiflash.com/v1/urltoimage?access_key=c01c69456f234dd094bc4415e61018eb&url=${encodeURIComponent(url)}&format=webp&full_page=true&delay=${apiDelay}&scroll_page=true&response_type=image&no_cookie_banners=true&no_ads=true&no_tracking=true&element_overlap=true&wait_until=page_loaded${device === 'mobile' ? '&width=375&height=667' : ''}`;

    const response = await axios.get(apiFlashEndpoint, { responseType: 'arraybuffer' });
    if (response.status === 200) {
      const screenshot = new Blob([response.data], { type: 'image/webp' });
      const screenshotUrl = URL.createObjectURL(screenshot);
      setImageSrc(prev => ({ ...prev, [device]: screenshotUrl }));
      console.log(`${device} screenshot captured successfully:`, screenshotUrl);
      return screenshot;
    } else {
      throw new Error(`Failed to capture ${device} screenshot`);
    }
  };

  const uploadOriginalAndGridImages = async (desktopFile, mobileFile, title) => {
    try {
      const desktopOriginalId = await uploadImageToDirectus(desktopFile, 'desktop');
      const mobileOriginalId = await uploadImageToDirectus(mobileFile, 'mobile');

      const desktopGridImage = await createGridImage(desktopFile);
      const mobileGridImage = await createGridImage(mobileFile);

      const desktopGridId = await uploadImageToDirectus(desktopGridImage, 'desktop', 'grid');
      const mobileGridId = await uploadImageToDirectus(mobileGridImage, 'mobile', 'grid');

      const heatmapId = await saveHeatmapToCollection(title, desktopOriginalId, mobileOriginalId, desktopGridId, mobileGridId);
      console.log('Heatmap saved successfully with both desktop and mobile images.');
      return heatmapId;
    } catch (error) {
      console.error('Failed to upload images or save heatmap:', error);
      throw error;
    }
  };

  const uploadImageToDirectus = async (file, device, type = 'screenshot') => {
    const timestamp = new Date().toISOString().replace(/[-:.TZ]/g, '').slice(0, 14);
    const filename = `${type}_${device}_${timestamp}.webp`;
    const formData = new FormData();
    formData.append('file', file, filename);

    try {
      const uploadResponse = await directusAPI.post('https://panel.reislo.com/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(`Directus upload response for ${device} ${type}:`, uploadResponse.data);
      return uploadResponse.data.data.id;
    } catch (error) {
      console.error(`Error uploading ${device} ${type} file:`, error);
      throw error;
    }
  };

  const createGridImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const img = new Image();
        img.src = loadEvent.target.result;

        img.onload = function () {
          const gridDataUrl = drawGridOnImage(img, 100);
          fetch(gridDataUrl)
            .then((res) => res.blob())
            .then(resolve)
            .catch(reject);
        };

        img.onerror = (error) => {
          console.error('Error loading image for grid processing:', error);
          reject(error);
        };
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const saveHeatmapToCollection = async (title, desktopOriginalId, mobileOriginalId, desktopGridId, mobileGridId) => {
    try {
      const response = await directusAPI.post('https://panel.reislo.com/items/heatmaps', {
        title,
        screenshot: desktopOriginalId,
        screenshot_mobile: mobileOriginalId,
        coordinates_grid_screenshot: desktopGridId,
        coordinates_grid_screenshot_mobile: mobileGridId
      });
      console.log('Saved to collection:', response.data);
      return response.data.data.id;
    } catch (error) {
      console.error('Failed to save to collection:', error);
      throw error;
    }
  };

  const handleDrop = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return;

    setInputMethod('dropzone');
    setIsUploading(true);
    setUploadError(null);

    try {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = function (loadEvent) {
        const img = new Image();
        img.src = loadEvent.target.result;

        img.onload = function () {
          const processedURL = drawGridOnImage(img, 100);
          setImageSrc({ desktop: processedURL, mobile: processedURL });
        };

        img.onerror = (error) => {
          console.error('Error loading image for grid processing:', error);
          setUploadError('Error processing the grid image.');
        };
      };

      reader.onerror = (error) => {
        console.error('Error reading file:', error);
        setUploadError('Error reading the uploaded file.');
      };

      reader.readAsDataURL(file);

      const title = file.name.replace(/\.[^/.]+$/, "");
      const newHeatmapId = await uploadOriginalAndGridImages(file, file, title);
      setHeatmapId(newHeatmapId);
      console.log('Heatmap created successfully with the uploaded screenshot!');
      setSubmitStatus('success');
      setShowTooltip(true);
    } catch (error) {
      setUploadError('Failed to upload the screenshot or create the heatmap item.');
      console.error('Failed to upload the screenshot or create the heatmap item.', error);
      setSubmitStatus('error');
    } finally {
      setIsUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/*',
  });

  const handleTooltipResponse = (isOk) => {
    if (isOk) {
      navigate(`/predictions/${heatmapId}`);
    } else {
      setApiDelay(prev => prev + 5);
      setImageSrc({ desktop: '', mobile: '' });
      setInputMethod(null);
      if (inputMethod === 'search') {
        captureScreenshots(normalizeUrl(url));
      }
    }
    setShowTooltip(false);
  };

  const handleRemoveImage = () => {
    setImageSrc({ desktop: '', mobile: '' });
    setShowTooltip(false);
    setInputMethod(null);
  };

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader title="Heuristic AI Predictions" description="Navigate through the cards to view specific information or to make updates to existing projects." />
        {inputMethod !== 'dropzone' && (
          <form onSubmit={handleSearchSubmit} className="mx-auto mt-16">
            <div className="relative">
              <input type="search" id="default-search" className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type Your Page URL to Analyse" required value={url} onChange={handleSearchChange} />
              <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Search</button>
            </div>
          </form>
        )}
        {inputMethod !== 'search' && (
          <div {...getRootProps({ className: 'dropzone mt-4' })}>
            <input {...getInputProps()} />
            <p>Drag & drop a screenshot here, or click to select one</p>
          </div>
        )}
        {isUploading && (
          <div className="loader-container">
            <Loader isLoading={isUploading} submitStatus={submitStatus} />
          </div>
        )}
        {(imageSrc.desktop || imageSrc.mobile) && (
          <div className="mt-16 relative">

            <h2 className="mt-16 ">Desktop</h2>
            {imageSrc.desktop && <img src={imageSrc.desktop} alt="Desktop Screenshot" style={{ maxWidth: '100%' }} />}

            <h2 className="mt-16 ">Mobile</h2>
            {imageSrc.mobile && <img src={imageSrc.mobile} alt="Mobile Screenshot" className="w-full max-w-lg mx-auto " />}
            <button
              onClick={handleRemoveImage}
              className="absolute top-2 right-2 "
            >
              <XCircleIcon className="w-12 text-neutral-700 hover:text-neutral-900 hover:cursor-pointer" />
            </button>
          </div>
        )}
        {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
        {showTooltip && (
          <div className="fixed bg-opacity-95 max-w-xl mx-auto rounded-xl bottom-6 left-0 right-0 bg-gray-800 text-white p-4 flex justify-between text-center items-center">
            <span></span>
            <span className="text-center text-lg">Are the screenshots okay?</span>
            <div className="flex gap-4">
              <button
                onClick={() => handleTooltipResponse(false)}
                className="bg-transparent text-white p-2 rounded"
              >
                No, retry
              </button>
              <button
                onClick={() => handleTooltipResponse(true)}
                className="bg-primary-500 text-white p-2 px-4 rounded mr-2"
              >
                Yes, continue
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HeatmapsAnalysis;