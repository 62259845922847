import axios from 'axios';
import { checkRunStatus, listMessages, apiHeaders } from '../articlesGpt';
import { SEO_ASSISTANT_ID } from '../../../api';

const generateStructurePrompt = (articleContext, keywords, toneOfVoice, complexity, language) => {
  return `Provide an Article structure in PURE JSON format! Don't add your comment outside the {buckets} !!!: 
  {
      "sections": [
        {
          "header": "Use H2 at the top of each section. Include keywords to aid readability and SEO.",
          "paragraph": "Explain what should be in this section and add number of words to meet requirements"
        }
      ],
  }`;
};

const generateArticleStructure = async (articleContext, keywords, toneOfVoice, complexity, language) => {
  try {
    if (!articleContext || articleContext.trim() === '') {
      throw new Error('Article context is empty. Please provide context for the article.');
    }

    const threadRes = await axios.post('https://api.openai.com/v1/threads', {}, { headers: apiHeaders });
    if (!threadRes.data || !threadRes.data.id) {
      throw new Error('Failed to create thread');
    }
    const threadId = threadRes.data.id;
    console.log('Thread created with ID:', threadId);

    const messages = [
      {
        role: 'user',
        content: `
        You are the Copywriter. First, think about what information the reader of this article would be looking for. What sections should be included, and what is the most valuable way to organize the information? Article is top-of-tunnel content.
        Article Context: ${articleContext},
        Keywords: ${keywords.join(', ')} ,
        Tone of Voice: ${toneOfVoice},
        Complexity (Fog Index): ${complexity},
        Language: ${language},
        `
      },
      {
        role: 'assistant',
        content: 'I will search through Files I have access to and create the article structure based on this information in the specified JSON format.'
      },
      {
        role: 'user',
        content: generateStructurePrompt(articleContext, keywords, toneOfVoice, complexity, language)
      }
    ];

    for (const message of messages) {
      const messageRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/messages`, message, { headers: apiHeaders });
      if (!messageRes.data) {
        throw new Error('Failed to add message');
      }
      console.log('Message added to thread:', messageRes.data);
    }

    const runRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/runs`, {
      assistant_id: SEO_ASSISTANT_ID,
      tool_resources: {
        file_search: {
          vector_store_ids: ['vs_0aZJbV6SHHz6zKagR2osdkio'] // Replace with your actual vector store ID
        }
      }
    }, { headers: apiHeaders });
    if (!runRes.data || !runRes.data.id) {
      throw new Error('Failed to run assistant');
    }
    const runId = runRes.data.id;
    console.log('Assistant run started with ID:', runId);

    let runData;
    while (true) {
      try {
        runData = await checkRunStatus(threadId, runId);
        console.log('Run status:', runData.status);
        if (runData.status === 'completed') {
          break;
        } else if (runData.status === 'failed') {
          console.error('Run failed. Details:', runData);
          throw new Error(`Assistant run failed: ${runData.last_error?.message || 'Unknown error'}`);
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (error) {
        console.error('Error during run status check:', error);
        throw error;
      }
    }

    console.log('Run completed:', runData);
    const messagesRes = await listMessages(threadId);
    console.log('Messages in thread:', messagesRes);

    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const generatedText = assistantMessage.content[0].text.value;
    console.log('Generated text:', generatedText);

    // Check for file search usage
    const annotations = assistantMessage.content[0].annotations || [];
    const fileCitations = annotations.filter(annotation => annotation.file_citation);
    if (fileCitations.length > 0) {
      console.log('File search used. Citations:', fileCitations);
    } else {
      console.log('File search not used in the response.');
    }

    return { generatedText, threadId }; // Return both the generated text and the threadId

  } catch (error) {
    console.error('Error in generating article structure:', error);
    if (error.response) {
      console.error('API response data:', error.response.data);
    }
    throw new Error(`Failed to generate article structure: ${error.response ? error.response.data.error.message : error.message}`);
  }
};

export default generateArticleStructure;
