import React, { useState, useEffect } from 'react';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import FormWithResponse from './formWithResponse';
import PersonasForm from './personasForm';
import { DIRECTUS_PROJECTS_ENDPOINT, handleAndSavePersona } from './api';

axiosRetry(axios, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: error => error.response?.status === 429,
});

export default function CreatePersonas() {
    const [output, setOutput] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleFormSubmit = async (formData) => {
        setLoading(true);
        try {
            const result = await handleAndSavePersona(formData);
            setOutput(result);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <PersonasForm handleSubmit={handleFormSubmit} />
            <FormWithResponse output={output} loading={loading} error={error} />
        </div>
    );
}