import React from 'react';
import { Link } from 'react-router-dom';

const Tokens = ({ role }) => {
  let content;

  // Determine the content based on the role
  switch (role) {
    case 'basic':
      content = (
        <Link to="/pricing">
          <span className="mt-4 inline-flex items-center rounded-md bg-secondary-50 px-3 mb-4 py-1 text-sm text-secondary-600 ring-1 ring-inset ring-secondary-500/10">Plan Basic</span>
        </Link>
      );
      break;
    case 'Enterprise':
      content = (
        <Link to="/pricing">
          <span className="mt-4 inline-flex items-center rounded-md bg-secondary-50 px-3 mb-4 py-1 text-sm text-secondary-600 ring-1 ring-inset ring-secondary-500/10">Plan Enterprise</span>
        </Link>
      );
      break;
    case 'Premium':
      content = (
        <Link to="/pricing">
          <span className="mt-4 inline-flex items-center rounded-md bg-secondary-50 px-3 mb-4 py-1 text-sm text-secondary-600 ring-1 ring-inset ring-secondary-500/10">Plan Premium</span>
        </Link>
      );
      break;
    case 'Admin':
      content = (
        <Link to="/pricing">
          <span className="mt-4 inline-flex items-center rounded-md bg-secondary-50 px-3 mb-4 py-1 text-sm text-secondary-600 ring-1 ring-inset ring-secondary-500/10">Admin</span>
        </Link>
      );
      break;
    default:
      content = (
        <Link to="/pricing">
          <span className="mt-4 inline-flex items-center rounded-md bg-primary-50 px-3 mb-4 py-1 text-sm text-primary-500 ring-1 ring-inset ring-primary-500/10">Upgrade Plan</span>
        </Link>
      );
  }

  return content; // Render the appropriate content based on the role
};

export default Tokens;
