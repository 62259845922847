import React from 'react';
import parseQualityReportToHTML from '../../QualityCheck/parseQualityReportToHTML';

const QualityCheck = ({ qualityReport, onCheckQuality, isCheckingQuality }) => {
  return (
    <div className="px-4 pt-2">
      <h2 className="text-xl font-medium mb-4">Quality Check Report</h2>
      {qualityReport && Array.isArray(qualityReport) && qualityReport.length > 0 
        ? parseQualityReportToHTML(qualityReport)
        : <p>No quality report available. Click the button below to generate a report.</p>
      }
      <button
        onClick={onCheckQuality}
        className="bg-primary-500 text-white px-4 py-2 mt-4 w-full"
        disabled={isCheckingQuality}
      >
        {isCheckingQuality ? 'Loading...' : 'Check Content Quality'}
      </button>
    </div>
  );
};

export default QualityCheck;