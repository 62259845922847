import React, { useState, useRef, useEffect, useCallback } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../../api';
import generateArticleStructure from './generateArticleStructure';
import parseAndRenderEditorContent from './parseRender';
import ArticleForm from './FirstStep';
import { useNavigate } from 'react-router-dom';
import { saveArticleToDirectus, updateArticleStatusToOngoing } from '../saveArticle';
import generateArticle from '../SingleArticle/generateArticle';

export default function CreateArticleDraft() {
  const [articleBody, setArticleBody] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [contentType, setContentType] = useState('blogpost');
  const [toneOfVoice, setToneOfVoice] = useState('friendly');
  const [complexity, setComplexity] = useState('fog index: 10');
  const [language, setLanguage] = useState('polish');
  const [loading, setLoading] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [isDraftGenerated, setIsDraftGenerated] = useState(false);
  const [generatedArticleId, setGeneratedArticleId] = useState(null);
  const [articleData, setArticleData] = useState(null);
  const [editorHasContent, setEditorHasContent] = useState(false);
  const [articleContext, setArticleContext] = useState('');

  const navigate = useNavigate();
  const editorRef = useRef(null);

  const directusAPI = axios.create({
    baseURL: DIRECTUS_INSTANCE,
  });

  directusAPI.interceptors.request.use(config => {
    const token = localStorage.getItem('directus_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, error => Promise.reject(error));

  const title = "Create Article Draft";
  const description = "Select your desired type - be it personas, buyer personas, or proto personas, and fill out the form below with precision. Accurate and detailed information ensures that your personas capture the essence of your target audience.";

  const handleContinue = () => {
  if (editorHasContent) {
    // Navigate immediately
    navigate('/articles');

    // Perform article creation in the background
    (async () => {
      setLoading(true);
      try {
        const outputData = await editorRef.current.save();

        let articleId = generatedArticleId;
        if (!articleId) {
          const newArticle = await saveArticleToDirectus({
            title: outputData.blocks[0].data.text || 'Untitled',
            article_draft: JSON.stringify(outputData),
            keywords: keywords,
            contentType: contentType,
            toneOfVoice: toneOfVoice,
            fog_index: complexity,
            language: language,
          });
          articleId = newArticle.data.id;
          setGeneratedArticleId(articleId);
        }

        await updateArticleStatusToOngoing(articleId);

        const response = await directusAPI.get(`/items/articles/${articleId}`);
        const currentArticleData = response.data.data;

        const generatedContent = await generateArticle(
          articleId,
          outputData,
          keywords,
          toneOfVoice,
          complexity,
          language,
          contentType
        );

        await saveArticleToDirectus({
          id: articleId,
          article_body: generatedContent,
          status: 'published',
          title: currentArticleData.title,
          complexity: currentArticleData.fog_index,
          keywords: keywords,
          contentType: contentType,
          toneOfVoice: toneOfVoice,
          language: language,
        }, true);

        // Optionally, you can dispatch an action or update state to reflect the new article
        // For example: dispatch(addNewArticle(articleId));
      } catch (error) {
        console.error('Error generating article:', error);
        setSaveError('Error generating article: ' + error.message);
        // Optionally, you can show an error notification to the user
      } finally {
        setLoading(false);
      }
    })();
  }
};

const handleSave = useCallback(async () => {
  if (editorRef.current) {
    try {
      const outputData = await editorRef.current.save();
      console.log('Output Data:', outputData);
      if (outputData.blocks.length === 0) {
        console.error('No content blocks to save');
        return;
      }

      let title = outputData.blocks[0]?.data?.text || 'Untitled';

      const articleDataToSave = {
        id: generatedArticleId, // Zawsze dołączamy ID, jeśli istnieje
        title: title,
        article_draft: outputData,
        keywords: keywords,
        content_type: contentType,
        tone_of_voice: toneOfVoice,
        fog_index: complexity,
        language: language,
        thread_id: articleData?.thread_id || null,
      };

      console.log('Saving article with ID:', generatedArticleId);

      const savedArticle = await saveArticleToDirectus(articleDataToSave);
      
      if (!generatedArticleId) {
        setGeneratedArticleId(savedArticle.data.id);
      }
      
      setArticleData(savedArticle.data);
      setLastSaved(new Date().toISOString());
      setSaveError('');
      setIsDraftGenerated(true);
      console.log('Article saved successfully:', savedArticle);

    } catch (error) {
      setSaveError('Saving failed: ' + error.message);
      console.log('Saving failed:', error);
    }
  }
}, [keywords, contentType, toneOfVoice, complexity, language, generatedArticleId, articleData]);

  useEffect(() => {
    if (!editorRef.current) {
      editorRef.current = new EditorJS({
        holder: 'editorjs',
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link'],
            config: {
              placeholder: 'Enter a header',
              levels: [2, 3],
              defaultLevel: 2,
            },
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
        },
        onChange: async () => {
          const outputData = await editorRef.current.save();
          setEditorHasContent(outputData.blocks.length > 0);
        }
      });
    }

    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    console.log('Article Context in CreateArticleDraft:', articleContext);
  }, [articleContext]);

// In CreateArticleDraft.js

// In CreateArticleDraft.js

// In CreateArticleDraft.js

const autoGenerateAndRender = async () => {
  setLoading(true);
  try {
    console.log('Article Context:', articleContext);
    const { generatedText, threadId } = await generateArticleStructure(articleContext, keywords, toneOfVoice, complexity, language);
    
    console.log('Generated Text:', generatedText);
    console.log('Thread ID:', threadId);

    await parseAndRenderEditorContent(generatedText, editorRef);
    
    // Save the article with the thread_id and article_draft
    const outputData = await editorRef.current.save();
    
    const articleDataToSave = {
      title: outputData.blocks[0]?.data?.text || 'Untitled',
      article_draft: JSON.stringify(outputData), // Ensure this is stringified
      keywords: keywords,
      content_type: contentType,
      toneOfVoice: toneOfVoice,
      fog_index: complexity,
      language: language,
      thread_id: threadId,
    };
    
    console.log('Article data to save:', articleDataToSave);

    const savedArticle = await saveArticleToDirectus(articleDataToSave);
    console.log('Saved article:', savedArticle);

    setGeneratedArticleId(savedArticle.data.id);
    setArticleData(savedArticle.data);
    setLastSaved(new Date().toISOString());
    setSaveError('');
    setIsDraftGenerated(true);
    setEditorHasContent(true);


  } catch (error) {
    setSaveError('Error during generation or saving: ' + error.message);
    console.error('Error during generation:', error);
  } finally {
    setLoading(false);
  }
};

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    if (!articleContext || articleContext.trim() === '') {
      setSaveError('Article context is empty. Please provide context for the article.');
      return;
    }
    autoGenerateAndRender();
  }, [articleContext, keywords, contentType, toneOfVoice, complexity, language]);


  const toggleAdvancedSettings = () => setShowAdvancedSettings(!showAdvancedSettings);

  const handleAddKeyword = () => {
    if (keywordInput && !keywords.includes(keywordInput)) {
      setKeywords([...keywords, keywordInput]);
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setKeywords(keywords.filter((k) => k !== keyword));
  };

  return (
    <div className="lg:pl-24 bg-white w-full">
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-7xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-medium leading-6 text-primary-900">{title}</h1>
            <p className="mt-4 text-base text-primary-900 opacity-60 max-w-4xl">{description}</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex gap-4">
            <button
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
              className={`inline-flex items-center justify-center rounded-full  px-6 py-3 text-base  shadow-sm focus:outline-none focus:ring-2  ${editorHasContent && !loading
                ? 'bg-transparent boder-primary-500 border text-primary-500'
                : 'bg-primary-500 cursor-pointer focus:ring-primary-500 focus:ring-offset-2 text-white'
              }`}>
              {loading ? 'Generating...' : 'Generate Draft'}
            </button>
            <button
              onClick={handleContinue}
              disabled={!editorHasContent || loading}
              className={`inline-flex items-center justify-center rounded-full px-6 py-3 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${editorHasContent && !loading
                ? 'bg-primary-500 hover:bg-primary-600 focus:ring-primary-500'
                : 'bg-gray-300 cursor-not-allowed'
                }`}>
              Generate Article
            </button>
          </div>
        </div>

        <div className="flex gap-8 mt-16">
          <div className="w-5/12">
            <ArticleForm
              articleContext={articleContext}
              setArticleContext={setArticleContext}
              keywords={keywords}
              keywordInput={keywordInput}
              setKeywordInput={setKeywordInput}
              contentType={contentType}
              setContentType={setContentType}
              toneOfVoice={toneOfVoice}
              setToneOfVoice={setToneOfVoice}
              complexity={complexity}
              setComplexity={setComplexity}
              language={language}
              setLanguage={setLanguage}
              loading={loading}
              handleSave={handleSave}
              handleAddKeyword={handleAddKeyword}
              handleRemoveKeyword={handleRemoveKeyword}
              toggleAdvancedSettings={toggleAdvancedSettings}
              showAdvancedSettings={showAdvancedSettings}
              saveError={saveError} />
          </div>
          <div className="bg-white p-8 w-full min-h-screen rounded-xl border border-neutral-100">
            <div id="editorjs"></div>
          </div>
        </div>
      </form >
    </div >
  );
}