import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { DIRECTUS_DATASETS_ENDPOINT, DIRECTUS_PROJECTS_ENDPOINT } from '../../api'; // Adjust the import paths as necessary
import { CloudArrowUpIcon } from '@heroicons/react/24/outline';


const CreateDataSet = () => {
  const [datasetName, setDatasetName] = useState('');
  const [datasetType, setDatasetType] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projects, setProjects] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token'); // Retrieve the stored token
  const userId = localStorage.getItem('user_id'); // Assuming the user ID is stored in localStorage

  const title = "Create Dataset";
  const description = "";

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setProjects(response.data.data);
      } catch (error) {
        setError('Failed to fetch projects. ' + (error.response?.data?.message || error.message));
      }
    };

    fetchProjects();
  }, [token]);

  const handleDrop = (acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: handleDrop });

  const handleRemoveFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleProjectChange = (event) => {
    setSelectedProjectId(event.target.value);
    if (event.target.value === 'new-project') {
      navigate('/projects/create'); // Navigate to the create project page
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!datasetName) {
      errors.datasetName = "Dataset name is required";
    }
    if (!selectedProjectId || selectedProjectId === "Select Project") {
      errors.selectedProjectId = "A related project must be selected";
    }
    if (files.length === 0) {
      errors.files = "At least one file must be uploaded";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    setError('');

    try {
      const formData = new FormData();
      formData.append('name', datasetName);
      formData.append('type', datasetType);
      formData.append('user_created', userId);
      formData.append('project', selectedProjectId);
      files.forEach((file) => {
        formData.append('file', file);
      });

      const response = await axios.post(DIRECTUS_DATASETS_ENDPOINT, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        navigate('/datasets'); // Navigate to the datasets page or dashboard after successful creation
      }
    } catch (error) {
      setError('Failed to create dataset. ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lg:ml-72">

      {isLoading ? <div className="mx-auto h-12 w-12 text-gray-400 animate-spin">loading</div> : null}
      <div className="mx-auto bg-white">
        <div className="pb-12">
          <form onSubmit={handleSubmit} className="mt-10 space-y-8">
            <div className="sm:flex sm:items-center mt-16">
              <div className="sm:flex-auto">
                <h1 className="text-4xl font-medium leading-6 text-primary-900">{title}</h1>
                <p className="mt-4 text-base text-primary-900 opacity-60 max-w-4xl">{description}</p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                  type="submit"
                  className="rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  {isLoading ? 'Creating...' : 'Create Dataset'}
                </button>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="dataset-name" className="block text-sm font-medium leading-6 text-gray-900">
                  Dataset Name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    placeholder="i.e. My Amazing Dataset"
                    id="dataset-name"
                    value={datasetName}
                    onChange={(e) => setDatasetName(e.target.value)}
                    required
                  />
                </div>
                {formErrors.datasetName && <span className="text-red-500 text-sm">{formErrors.datasetName}</span>}
              </div>

              <div className="sm:col-span-6">
                <label className="block text-sm font-medium text-gray-700 mb-1">Data Type</label>
                <select
                  className="w-full"
                  id="dataset-type"
                  value={datasetType}
                  onChange={(e) => setDatasetType(e.target.value)}
                >
                  <option value="user_interview_transcript">User Interview Transcript</option>
                  <option value="product_brochure">Product Brochure</option>
                  <option value="blog_post_article">Article or Blogpost Sample</option>
                  <option value="database">Database</option>
                </select>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="project-select" className="block text-sm font-medium leading-6 text-gray-900">
                  Related Project
                </label>
                <div className="mt-2">
                  <select required name="project" id="project-select" onChange={handleProjectChange} value={selectedProjectId}>
                    <option>Select Project</option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>{project.project_name}</option>
                    ))}
                    <option value="new-project">Create New Project</option>
                  </select>
                </div>
                {formErrors.selectedProjectId && <span className="text-red-500 text-sm">{formErrors.selectedProjectId}</span>}
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="files" className="block text-sm font-medium leading-6 text-gray-900">
                  Drag & Drop Files
                </label>
                <div
                  {...getRootProps()}
                  className={`mt-2 border-dashed border border-primary-200 rounded-md p-6 text-center cursor-pointer ${isDragActive ? 'bg-primary-200' : ''
                    }`}
                >
                  <input {...getInputProps()} />
                  <p className="text-primary-500 bg-primary-100 rounded-xl px-8 py-16 text-center flex flex-col justify-center items-center gap-4">
                    <CloudArrowUpIcon className="w-12" />
                    {isDragActive ? 'Drop files here...' : 'Drag & drop some files here, or click to select files'}
                  </p>
                </div>
                <div className="mt-2">
                  {files.length > 0 && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-900">Selected Files</h4>
                      <ul>
                        {files.map((file, index) => (
                          <li key={index} className="text-sm text-primary-200 flex justify-between items-center">
                            <span>{file.name}</span>
                            <button
                              type="button"
                              className="text-red-500 hover:text-red-700"
                              onClick={() => handleRemoveFile(file)}
                            >
                              Remove
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {formErrors.files && <span className="text-red-500 text-sm">{formErrors.files}</span>}
                </div>
              </div>
            </div>

            {error && <div className="text-red-500 text-sm mt-2">{error}</div>}

          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateDataSet;