import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_ARTICLES_ENDPOINT } from '../../../api';
import { format, parseISO } from 'date-fns';
import { CloudArrowUpIcon, ArrowLeftIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import checkContentQuality from '../QualityCheck/articleQualityCheck';
import Editor from './Editor';
import generateArticle from '../SingleArticle/generateArticle';
import { updateArticleStatusToOngoing } from '../saveArticle';
import ArticleDetailsTabs from './Tabs/ArticleDetailsTabs';

const ArticleDraft = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [saveError, setSaveError] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [qualityReport, setQualityReport] = useState({});
  const [isCheckingQuality, setIsCheckingQuality] = useState(false);
  const [status, setStatus] = useState('draft');
  const [articleContent, setArticleContent] = useState(null);
  const editorRef = useRef(null);
  const token = localStorage.getItem('directus_token');
  const autosaveTimeoutRef = useRef(null);


  const parseArticleContent = (content) => {
    if (!content) return { blocks: [] };

    try {
      let parsedContent;
      if (typeof content === 'string') {
        parsedContent = JSON.parse(content);
      } else {
        parsedContent = content;
      }

      const applyBoldFormatting = (text) => {
        return text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
      };

      if (parsedContent && typeof parsedContent.content === 'string') {
        const lines = parsedContent.content.split('\n');
        const blocks = [];
        let currentTable = null;

        lines.forEach(line => {
          line = line.trim();
          if (line.startsWith('# ')) {
            blocks.push({ type: 'header', data: { text: applyBoldFormatting(line.substring(2)), level: 1 } });
          } else if (line.startsWith('## ')) {
            blocks.push({ type: 'header', data: { text: applyBoldFormatting(line.substring(3)), level: 2 } });
          } else if (line.startsWith('### ')) {
            blocks.push({ type: 'header', data: { text: applyBoldFormatting(line.substring(4)), level: 3 } });
          } else if (line.startsWith('#### ')) {
            blocks.push({ type: 'header', data: { text: applyBoldFormatting(line.substring(5)), level: 4 } });
          } else if (line.startsWith('- ')) {
            if (blocks.length > 0 && blocks[blocks.length - 1].type === 'list') {
              blocks[blocks.length - 1].data.items.push(applyBoldFormatting(line.substring(2)));
            } else {
              blocks.push({ type: 'list', data: { style: 'unordered', items: [applyBoldFormatting(line.substring(2))] } });
            }
          } else if (line.startsWith('|')) {
            if (!currentTable) {
              currentTable = { type: 'table', data: { withHeadings: true, content: [] } };
              blocks.push(currentTable);
            }
            const cells = line.split('|').slice(1, -1).map(cell => applyBoldFormatting(cell.trim()));
            currentTable.data.content.push(cells);
          } else if (line === '---') {
            blocks.push({ type: 'delimiter', data: {} });
          } else if (line.trim() === '') {
            currentTable = null;
          } else {
            blocks.push({
              type: 'paragraph',
              data: {
                text: applyBoldFormatting(line)
              }
            });
          }
        });

        return { blocks };
      }

      if (parsedContent && Array.isArray(parsedContent.blocks)) {
        return {
          blocks: parsedContent.blocks.map(block => {
            if (block.type === 'list') {
              return {
                ...block,
                data: {
                  ...block.data,
                  items: block.data.items.map(applyBoldFormatting)
                }
              };
            } else if (block.type === 'table') {
              return {
                ...block,
                data: {
                  ...block.data,
                  content: block.data.content.map(row => row.map(applyBoldFormatting))
                }
              };
            } else if (block.type === 'paragraph' || block.type === 'header') {
              return {
                ...block,
                data: {
                  ...block.data,
                  text: applyBoldFormatting(block.data.text)
                }
              };
            }
            return block;
          })
        };
      }

      return {
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: applyBoldFormatting(typeof parsedContent === 'string' ? parsedContent : JSON.stringify(parsedContent))
            }
          }
        ]
      };
    } catch (error) {
      console.error('Error parsing article content:', error);
      return {
        blocks: [
          {
            type: 'paragraph',
            data: {
              text: typeof content === 'string' ? content : JSON.stringify(content)
            }
          }
        ]
      };
    }
  };

  const fetchArticle = useCallback(async () => {
    try {
      const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = response.data.data;

      setTitle(data.title);
      setSlug(data.slug);
      setLastSaved(data.date_updated);
      setStatus(data.status);

      if (data.article_quality_check) {
        const formattedQualityCheck = Array.isArray(data.article_quality_check)
          ? data.article_quality_check
          : [{ quality_control_report: data.article_quality_check }];
        setQualityReport(formattedQualityCheck);
      }

      const content = data.status === 'draft' ? data.article_draft : data.article_body;
      setArticleContent(parseArticleContent(content));

      setLoading(false);
    } catch (error) {
      console.error('Error fetching article data:', error);
      setSaveError('Error fetching article data: ' + error.message);
    }
  }, [id, token]);

  useEffect(() => {
    fetchArticle();
  }, [fetchArticle]);

  const goBack = () => {
    navigate('/articles');
  };


  const saveArticleToDirectus = async (articleData) => {
    try {
      const response = await axios.patch(`${DIRECTUS_ARTICLES_ENDPOINT}/${id}`, articleData, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error saving article:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        throw new Error(`Error saving article: ${error.response.data.errors[0].message}`);
      } else if (error.request) {
        console.error('No response received:', error.request);
        throw new Error('Error saving article: No response received from server');
      } else {
        console.error('Error setting up request:', error.message);
        throw new Error(`Error saving article: ${error.message}`);
      }
    }
  };


  const handleGenerateArticle = async () => {
    if (editorRef.current) {
      setLoading(true);
      try {
        const outputData = await editorRef.current.save();

        await updateArticleStatusToOngoing(id);

        const generatedContent = await generateArticle(
          id,
          outputData,
          await fetchKeywordsFromDirectus(id),
          await fetchToneOfVoiceFromDirectus(id),
          await fetchComplexityFromDirectus(id),
          await fetchLanguageFromDirectus(id)
        );

        const articleData = {
          id: id,
          title: outputData.blocks[0]?.data?.text || 'Untitled',
          article_body: generatedContent, // This will be stringified in saveArticleToDirectus
          status: 'published',
          keywords: await fetchKeywordsFromDirectus(id),
          tone_of_voice: await fetchToneOfVoiceFromDirectus(id),
          fog_index: await fetchComplexityFromDirectus(id),
          language: await fetchLanguageFromDirectus(id),
        };

        const savedArticle = await saveArticleToDirectus(articleData, true);

        setLastSaved(new Date().toISOString());
        setStatus('published');
        setArticleContent(parseGeneratedContent(generatedContent));
        console.log('Article generated and saved successfully');
        setSaveError('');

        await fetchArticle();
      } catch (error) {
        console.error('Error generating article:', error);
        setSaveError(`Error generating article: ${error.message}. Please check the console for more details.`);
      } finally {
        setLoading(false);
      }
    }
  };

  const parseGeneratedContent = (content) => {
    const lines = content.split('\n');
    const blocks = [];
    let currentHeader = null;

    lines.forEach(line => {
      line = line.trim();
      if (line.startsWith('# ')) {
        blocks.push({ type: 'header', data: { text: line.substring(2), level: 1 } });
      } else if (line.startsWith('## ')) {
        blocks.push({ type: 'header', data: { text: line.substring(3), level: 2 } });
      } else if (line.startsWith('### ')) {
        blocks.push({ type: 'header', data: { text: line.substring(4), level: 3 } });
      } else if (line.startsWith('- ')) {
        if (blocks.length > 0 && blocks[blocks.length - 1].type === 'list') {
          blocks[blocks.length - 1].data.items.push(line.substring(2));
        } else {
          blocks.push({ type: 'list', data: { style: 'unordered', items: [line.substring(2)] } });
        }
      } else if (line !== '') {
        blocks.push({ type: 'paragraph', data: { text: line } });
      }
    });

    return { blocks };
  };

  const fetchToneOfVoiceFromDirectus = async (articleId) => {
    try {
      const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}/${articleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.toneOfVoice || 'friendly';
    } catch (error) {
      console.error('Error fetching tone of voice:', error);
      return 'friendly';
    }
  };

  const fetchComplexityFromDirectus = async (articleId) => {
    try {
      const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}/${articleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.fog_index || 'fog index: 10';
    } catch (error) {
      console.error('Error fetching complexity:', error);
      return 'fog index: 10';
    }
  };

  const fetchLanguageFromDirectus = async (articleId) => {
    try {
      const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}/${articleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.language || 'polish';
    } catch (error) {
      console.error('Error fetching language:', error);
      return 'polish';
    }
  };

  const handleSave = useCallback(async () => {
    if (editorRef.current) {
      try {
        const outputData = await editorRef.current.save();
        console.log('Output Data:', outputData);

        const titleBlock = outputData.blocks.find(block => block.type === 'header' && block.data.level === 1);
        const newTitle = titleBlock ? titleBlock.data.text : title;

        const articleData = {
          title: newTitle,
          slug,
          [status === 'draft' ? 'article_draft' : 'article_body']: JSON.stringify(outputData),
          article_quality_check: qualityReport,
        };

        console.log('Article Data to Save:', articleData);

        await saveArticleToDirectus(articleData);
        setTitle(newTitle);
        setLastSaved(new Date().toISOString());
        console.log('Article saved successfully');
        setSaveError('');
      } catch (error) {
        console.log('Saving failed: ', error);
        setSaveError('Error saving article: ' + error.message);
      }
    }
  }, [title, slug, status, qualityReport, id, token]);

  const handleEditorChange = useCallback(() => {
    if (autosaveTimeoutRef.current) {
      clearTimeout(autosaveTimeoutRef.current);
    }
    autosaveTimeoutRef.current = setTimeout(handleSave, 2000);
  }, [handleSave]);

  const handleCheckContentQuality = async () => {
    if (editorRef.current) {
      setIsCheckingQuality(true);
      try {
        const outputData = await editorRef.current.save();
        // Fetch keywords from Directus or use a state variable if you're already storing them
        const keywords = await fetchKeywordsFromDirectus(id); // Implement this function to fetch keywords
        const result = await checkContentQuality({ title, slug, article_body: outputData }, keywords);
        const formattedResult = Array.isArray(result) ? result : [{ quality_control_report: result }];
        setQualityReport(formattedResult);

        const articleData = {
          article_body: JSON.stringify(outputData),
          article_quality_check: formattedResult,
        };
        await saveArticleToDirectus(articleData);
        setLastSaved(new Date().toISOString());
        console.log('Quality check saved successfully', formattedResult);
        setSaveError('');
      } catch (error) {
        console.error('Quality check saving failed: ', error);
        setSaveError('Error during quality check: ' + error.message);
      } finally {
        setIsCheckingQuality(false);
      }
    }
  };

  // Implement this function to fetch keywords from Directus
  const fetchKeywordsFromDirectus = async (articleId) => {
    try {
      const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}/${articleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data.data.keywords || [];
    } catch (error) {
      console.error('Error fetching keywords:', error);
      return [];
    }
  };
  function formatDateUpdated(date) {
    if (!date) return 'No update date provided';
    const now = new Date();
    const updatedDate = parseISO(date);
    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;
    if (diffInSeconds < 60) return `${Math.floor(diffInSeconds)} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    return format(updatedDate, 'dd MMM yyyy');
  }
  return (
    <div className="pb-40 lg:pl-0 bg-white w-full flex flex-col">
      <div>

        
        <div className="flex gap-4 justify-between py-2 items-center pl-24 pr-8 border-b border-neutral-200">
          <div className="cursor-pointer" onClick={goBack}>
            <div className="bg-white rounded-full p-3 flex items-center justify-center">
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
          <div className="flex gap-2 items-center cursor-pointer">
            {loading ? (
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-green-500" role="status">
                <span className="visually-hidden"></span>
              </div>
            ) : (
              <CloudArrowUpIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
            )}
            <span className="text-sm text-neutral-600">Saved {formatDateUpdated(lastSaved)}</span>
          </div>
          <div>
            <div className="cursor-pointer">
              <div className="bg-white  rounded-full p-3 flex items-center justify-center">
                <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>


      </div>
      <div>
        <div className="flex gap-40">
          <div className="flex-1 lg:pl-72 pr-8">
            {saveError && <div className="text-red-500 mb-4">{saveError}</div>}
            <div className="flex flex-col space-y-4">
              <div className="final-article">
                <Editor
                  initialData={articleContent}
                  onChange={handleEditorChange}
                  editorRef={editorRef}
                  title={title}
                />
              </div>
            </div>
          </div>
          <div className="w-1/5 border-l border-neutral-200">
            {status === 'draft' && (
              <div className="bg-neutral-50 p-6 mb-6">
                <h2></h2>
                <button
                  onClick={handleGenerateArticle}
                  disabled={loading}
                  className={`inline-flex items-center justify-center rounded-full w-full px-6 py-3 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${!loading
                    ? 'bg-primary-500 hover:bg-primary-600 focus:ring-primary-500'
                    : 'bg-gray-300 cursor-not-allowed'
                    }`}
                >
                  {loading ? 'Generating...' : 'Generate Article'}
                </button>
              </div>
            )}
            <ArticleDetailsTabs
              articleDetails={{
                status,
                slug,
                // Add other relevant details here
              }}
              qualityReport={qualityReport}
              onCheckQuality={handleCheckContentQuality}
              isCheckingQuality={isCheckingQuality}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDraft;