// utils.js
export const drawGridOnImage = (image, cellSize = 100) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const imgWidth = image.width;
  const imgHeight = image.height;

  canvas.width = imgWidth;
  canvas.height = imgHeight;

  context.drawImage(image, 0, 0);

  const numCellsX = Math.ceil(imgWidth / cellSize);
  const numCellsY = Math.ceil(imgHeight / cellSize);

  for (let y = 0; y < numCellsY; y++) {
    for (let x = 0; x < numCellsX; x++) {
      const topLeftX = x * cellSize;
      const topLeftY = y * cellSize;

      context.strokeStyle = 'blue';
      context.lineWidth = 1;
      context.strokeRect(topLeftX, topLeftY, cellSize, cellSize);

      const label = x + y * numCellsX;

      context.fillStyle = 'rgba(255, 255, 255, 0.5)';
      context.fillRect(topLeftX, topLeftY, cellSize, cellSize);

      context.fillStyle = 'blue';
      context.font = '20px Arial';
      context.fillText(label, topLeftX + 5, topLeftY + 25);
    }
  }

  return canvas.toDataURL();
};