import axios from 'axios';
import axiosRetry from 'axios-retry';
export const DIRECTUS_INSTANCE = 'https://panel.reislo.com';
export const OPENAI_COMPLETIONS_INSTANCE = 'https://api.openai.com/v1/chat/completions';
export const DIRECTUS_PERSONAS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/personas`;
export const DIRECTUS_PROJECTS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/projects`;
export const DIRECTUS_ARTICLES_ENDPOINT = `${DIRECTUS_INSTANCE}/items/articles`;

export const DIRECTUS_USER_JOURNEYS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/userjourney`;
export const DIRECTUS_FILES_ENDPOINT = `${DIRECTUS_INSTANCE}/files`;

const REACT_APP_GPT_API_KEY = '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';
const SEO_ASSISTANT_ID = 'asst_OGN0UzCYtlXFPJAWxLZ3Q3BG';

export const apiHeaders = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`,
  'OpenAI-Beta': 'assistants=v2'
};

// Setup axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry to automatically retry requests
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

directusAPI.interceptors.response.use(response => response, handleApiError);

function handleApiError(error) {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
}

// Reuse existing functions (import them if needed or add definition if standalone)
async function fetchAvailableImages(gender) {
  const token = localStorage.getItem('directus_token');
  let folderId;

  if (gender === 'Female') {
    folderId = '5614aeeb-3cec-4dbd-a8c4-aba3df1660eb';
  } else if (gender === 'Male') {
    folderId = 'de2680b7-7c23-4cfb-bb7b-bac7ac721db3';
  } else {
    throw new Error('Invalid gender specified');
  }

  try {
    const response = await axios.get(`https://panel.reislo.com/files?filter[folder][_eq]=${folderId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data.data; // Return the list of files
  } catch (error) {
    console.error('Failed to fetch images from Directus:', error);
    throw error;
  }
}

function getRandomImageId(images) {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex]?.id;
}

async function uploadAvatar(file) {
  const token = localStorage.getItem('directus_token');
  const formData = new FormData();
  formData.append('file', file);

  try {
    const uploadResponse = await axios.post('https://panel.reislo.com/files', formData, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return uploadResponse.data.data.id; // Return the ID of the uploaded file
  } catch (error) {
    console.error('Failed to upload avatar to Directus:', error);
    throw error;
  }
}

function textToEditorJsData(text, type = 'paragraph') {
  const lines = text.split('\n');
  const blocks = lines.map(line => {
    if (line.startsWith('###')) {
      return {
        type: 'header',
        data: { text: line.replace(/###/g, '').trim(), level: 2 }
      };
    }
    return {
      type: type,
      data: { text: line.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>').replace(/###/g, '') }
    };
  });
  return { blocks };
}

function parseGPTTextResponse(gptTextResponse) {
  let motivations = "", needs = "", frustrations = "", biography = "";
  const lines = gptTextResponse.split('\n');
  let currentSection = null;

  const regexSections = {
    motivations: /^(?:\*\*|#+)\s*Motivations\s*(?:\*\*|#+)\s*:?$/im,
    needs: /^(?:\*\*|#+)\s*Needs\s*(?:\*\*|#+)\s*:?$/im,
    frustrations: /^(?:\*\*|#+)\s*Frustrations\s*(?:\*\*|#+)\s*:?$/im,
    biography: /^(?:\*\*|#+)\s*Biography\s*(?:\*\*|#+)\s*:?$/im,
  };

  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();

    if (line.match(regexSections.motivations)) {
      currentSection = 'motivations';
    } else if (line.match(regexSections.needs)) {
      currentSection = 'needs';
    } else if (line.match(regexSections.frustrations)) {
      currentSection = 'frustrations';
    } else if (line.match(regexSections.biography)) {
      currentSection = 'biography';
    } else if (line.startsWith('**')) {
      currentSection = null;
    }

    if (currentSection) {
      if (currentSection === 'motivations') motivations += line + "\n";
      else if (currentSection === 'needs') needs += line + "\n";
      else if (currentSection === 'frustrations') frustrations += line + "\n";
      else if (currentSection === 'biography') biography += line + "\n";
    }
  }

  return {
    motivations: textToEditorJsData(motivations.trim(), 'paragraph'),
    needs: textToEditorJsData(needs.trim(), 'paragraph'),
    frustrations: textToEditorJsData(frustrations.trim(), 'paragraph'),
    biography: textToEditorJsData(biography.trim(), 'paragraph')
  };
}

export const handleAndSavePersona = async (postData) => {
  console.log('handleAndSavePersona started with postData:', postData);

  {/* https://platform.openai.com/playground/assistants?assistant=asst_OGN0UzCYtlXFPJAWxLZ3Q3BG&thread=thread_pPR8HLCYr9ixItn7gfbzPUDl 
    https://platform.openai.com/playground/assistants?assistant=asst_RdOPSJuDUqixiou8CA78XNBI&thread=thread_mwfiTQtQQiWleCATzpI4929o
    */}
  const { prompt, ...personaData } = postData;
  const messages = [
    {
      role: 'user',
      content: 'Your job is to Search Through the files and create personas based on files on next messages.'
    },
    {
      role: 'user',
      content: `Personas data:
            Name: ${personaData.name}
            Gender: ${personaData.gender}
            Age: ${personaData.age}
            Education: ${personaData.education}
            Occupation: ${personaData.occupation}
            Country: ${personaData.country}
            City: ${personaData.city}
            Family Life Cycle: ${personaData.familycycle}
            Family Size: ${personaData.familysize}
            Income: ${personaData.income}
            Biography: ${personaData.biography}
            Motivations: ${personaData.motivations}
            Needs: ${personaData.needs}
            Frustrations: ${personaData.frustrations}`
    },
    {
      role: 'assistant',
      content: 'I will prepare Motivations, Needs, Frustrations. Each section must contain at least 10 (!) extended descriptive points. Find coleraion of motivations in file id: file-4qilhEdP5P8XLlaNFqrxbh48 and attach footnotes to them from this file. '
    },
    {
      role: 'assistant',
      content: 'I will ensure that the Biography in context of the Project (how he may currently have touch with the Project and how currently resolving his problems) contains at least 200 words (I will count it) and is including regular user journey of working life with bolded brands, books, podcast authors, street names, software version or device models with year of production. Personas Data and be 100% Valid.'
    },
    {
      role: 'user',
      content: `Based on the all details provided, create perfect persona in context of project: ${postData.context}`
    },
    {
      role: 'assistant',
      content: 'I will focus only on my job and I will not write any extra comments or notes. Each section must be "### Sectionname ###"'
    }
  ];

  try {
    const threadRes = await axios.post('https://api.openai.com/v1/threads', {}, { headers: apiHeaders });
    if (!threadRes.data || !threadRes.data.id) {
      throw new Error('Failed to create thread');
    }
    const threadId = threadRes.data.id;
    console.log('Created Thread ID:', threadId);

    for (const message of messages) {
      console.log('Adding message to thread:', message);
      const messageRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/messages`, message, { headers: apiHeaders });
      if (!messageRes.data) {
        throw new Error('Failed to add message');
      }
      console.log('Message added successfully:', message);
    }

    const runRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/runs`, { assistant_id: SEO_ASSISTANT_ID }, { headers: apiHeaders });
    if (!runRes.data || !runRes.data.id) {
      throw new Error('Failed to run assistant');
    }
    const runId = runRes.data.id;
    console.log('Assistant run started with ID:', runId);

    let runData;
    while (true) {
      runData = await checkRunStatus(threadId, runId);
      console.log('Run status:', runData.status);
      if (runData.status === 'completed') {
        break;
      } else if (runData.status === 'failed') {
        throw new Error('Assistant run failed.');
      }
      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before checking again
    }

    const messagesRes = await listMessages(threadId);
    console.log('Messages in thread:', messagesRes);

    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const content = assistantMessage.content[0].text.value;
    console.log('Generated content (first 200 characters):', content.substring(0, 200));
    const parsedResponse = parseGPTTextResponse(content);
    const entireGPTResponse = JSON.stringify(parsedResponse, null, 2);
    const userId = localStorage.getItem('user_id');

    const finalDataToSave = {
      status: 'complete',
      type: postData.type,
      project: postData.project,
      name: postData.name,
      gender: postData.gender,
      age: postData.age,
      education: postData.education,
      occupation: postData.occupation,
      country: postData.country,
      city: postData.city,
      family_life_cycle: postData.familycycle,
      family_size: postData.familysize,
      income: postData.income,
      prompt: entireGPTResponse,
      motivations: JSON.stringify(parsedResponse.motivations),
      needs: JSON.stringify(parsedResponse.needs),
      frustrations: JSON.stringify(parsedResponse.frustrations),
      biography: JSON.stringify(parsedResponse.biography),
      mosaic_segment: 'test g-24',
      vals_segmentation: 'test achievers',
      owner: userId,
    };

    // Handle avatar separately
    if (postData.avatar) {
      finalDataToSave.avatar = await uploadAvatar(postData.avatar);
    } else {
      const availableImages = await fetchAvailableImages(postData.gender);
      finalDataToSave.avatar = getRandomImageId(availableImages);
    }

    // Save the persona data
    const savedData = await savePersonaData(finalDataToSave);
    console.log('Persona data saved successfully:', savedData);
    return savedData;
  } catch (error) {
    console.error('Error in handleAndSavePersona:', error.response ? error.response.data : error.message);
    throw error;
  }
};

export const postCompletion = async (postData) => {
  return await axios.post(OPENAI_COMPLETIONS_INSTANCE, postData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`
    }
  });
};

export const checkRunStatus = async (threadId, runId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, { headers: apiHeaders });
    return res.data;
  } catch (error) {
    console.error('Error checking run status:', error);
    throw error;
  }
};

export const listMessages = async (threadId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, { headers: apiHeaders });
    return res.data;
  } catch (error) {
    console.error('Error listing messages:', error);
    throw error;
  }
};

export const savePersonaData = async (dataToSave) => {
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found. Please log in.');
  }

  try {
    const response = await axios.post(DIRECTUS_PERSONAS_ENDPOINT, dataToSave, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error during saving persona data:', error.response ? error.response.data : error.message);
    throw error;
  }
};