// PersonasSuccess.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const PersonasSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="lg:pl-72">
      <div className="flex flex-col items-center justify-center h-full text-center mt-48">
        <h3 className="mt-2 text-2xl font-semibold text-gray-900">Persona has been sent</h3>
        <p className="mt-1 text-sm text-gray-500">Your persona has been sent successfully and will be visible in a few minutes.</p>
        <div className="mt-6">
          <button
            type="button"
            onClick={() => navigate('/personas')}
            className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go to Personas
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonasSuccess;
