import React, { useState, useEffect } from 'react';
import { useData } from '../../api/DataContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT } from '../../api';
import JourneyItemDrawer from './JourneyItemDrawer';


function CustomerJourneyTimeline() {
  const { id } = useParams();
  const { journeys, setJourneys, loading, setLoading, error, setError } = useData();
  const [currentJourney, setCurrentJourney] = useState(null);
  const userID = localStorage.getItem('user_id');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState(null);
  const [activePhaseIndex, setActivePhaseIndex] = useState(null);

  const openDrawer = (type, phaseIndex) => {
    setDrawerType(type);
    setActivePhaseIndex(phaseIndex);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setDrawerType(null);
    setActivePhaseIndex(null);
  };

  const handleSaveItem = async (phaseIndex, itemType, itemData) => {
    const updatedJourney = { ...currentJourney };
    if (itemType === 'Painpoint') {
      updatedJourney.phase[phaseIndex].painpoints.push({ single_painpoint: itemData.content, name: itemData.name });
    } else if (itemType === 'Gain') {
      if (!updatedJourney.phase[phaseIndex].gains) {
        updatedJourney.phase[phaseIndex].gains = [];
      }
      updatedJourney.phase[phaseIndex].gains.push({ single_gain: itemData.content, name: itemData.name });
    }
    setCurrentJourney(updatedJourney);
    await saveJourney(updatedJourney);
  };

  useEffect(() => {
    if (journeys.length > 0) {
      const journey = journeys.find(j => j.id.toString() === id);
      if (journey) {
        setCurrentJourney(journey);
      } else {
        setError('Journey not found');
      }
    } else {
      fetchJourney();
    }
  }, [id, journeys]);

  const fetchJourney = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('directus_token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      const response = await axios.get(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${id}`, config);
      if (response.data.data.owner === userID) {
        setCurrentJourney(response.data.data);
        setJourneys(prevJourneys => [...prevJourneys, response.data.data]);
      } else {
        setError('You do not have permission to view these details.');
      }
    } catch (err) {
      setError(`Failed to fetch journey details: ${err.response ? err.response.data : 'Server did not respond'}`);
    } finally {
      setLoading(false);
    }
  };

  const addPainPoint = async (phaseIndex) => {
    const updatedJourney = { ...currentJourney };
    updatedJourney.phase[phaseIndex].painpoints.push({ single_painpoint: "New Pain Point" });
    setCurrentJourney(updatedJourney);
    await saveJourney(updatedJourney);
  };

  const addGain = async (phaseIndex) => {
    const updatedJourney = { ...currentJourney };
    if (!updatedJourney.phase[phaseIndex].gains) {
      updatedJourney.phase[phaseIndex].gains = [];
    }
    updatedJourney.phase[phaseIndex].gains.push({ single_gain: "New Gain" });
    setCurrentJourney(updatedJourney);
    await saveJourney(updatedJourney);
  };

  const saveJourney = async (updatedJourney) => {
    try {
      const token = localStorage.getItem('directus_token');
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      await axios.patch(`${DIRECTUS_USER_JOURNEYS_ENDPOINT}/${id}`, { phase: updatedJourney.phase }, config);
      setJourneys(prevJourneys => prevJourneys.map(j => j.id === updatedJourney.id ? updatedJourney : j));
    } catch (error) {
      console.error('Failed to save changes:', error);
      setError('Failed to save changes. Please try again.');
    }
  };

  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + "..." : text;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!currentJourney) return <div>No journey data available.</div>;

  const chartData = {
    labels: currentJourney.phase.map(phase => phase.phase_name),
    datasets: [
      {
        label: 'Emotional Curve',
        data: currentJourney.phase.map(phase => phase.emotional_curve),
        fill: false,
        borderColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 220);
          gradient.addColorStop(0, '#3ED193');
          gradient.addColorStop(0.25, '#3ED193');
          gradient.addColorStop(0.5, '#EADC66');
          gradient.addColorStop(0.75, '#E7ABAB');
          gradient.addColorStop(1, '#E7ABAB');
          return gradient;
        },
      }
    ]
  };

  const chartOptions = {
    scales: {
      y: {
        min: -3,
        max: 3,
        afterBuildTicks: (scale) => {
          scale.ticks.splice(0, 1);
          scale.ticks.pop();
        },
        ticks: {
          stepSize: 1,
          callback: function (value) {
            const emojis = { '3': ' ', '2': '😍 2', '1': '🙂 1', '0': '😐 0', '-1': '😒 -1', '-2': '😔 -2 ', '-3': ' ' };
            return `${emojis[value] || value}`;
          }
        }
      },
      x: {
        offset: true,
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          callback: function () {
            return '';
          },
        },
        grid: {
          offset: true,
          display: false
        },
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        barThickness: 'flex',
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `Emotional State: ${tooltipItem.raw}`;
          }
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  const chartWidth = currentJourney.phase.length * 320;

  return (
    <div className="bg-white pt-24 lg:pl-72">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-4xl font-semibold leading-6 text-gray-900">{currentJourney.user_journey_name}</h1>
          <p className="mt-8 text-base text-gray-600">{currentJourney.user_journey_description}</p>
        </div>
      </div>

      <div className="customer-journey-map overflow-x-auto mt-16 min-h-screen">
        <div className="flex min-w-max gap-1">
          <div className="min-w-40 bg-primary-50 pl-3 text-sm rounded-lg pt-3">Steps</div>
          {currentJourney.phase.map((phase, index) => (
            <div key={index} className="w-80 bg-primary-50 pl-3 pt-3 text-sm rounded-lg">
              <input className="bg-primary-50" value={phase.phase_name} readOnly />
            </div>
          ))}
          <div className="w-40 bg-primary-100 p-3 rounded-lg">Add Column +</div>
        </div>

        <div className="flex min-w-max gap-1 my-2">
          <div className="min-w-40 bg-white p-3 text-sm rounded-lg border border-primary-100">Emotional Curve</div>
          <div className="border border-primary-100 rounded-lg pl-4" style={{ height: '220px', width: `${chartWidth}px` }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>

        <div className="flex min-w-max gap-1">
        <div className="min-w-40 bg-red-50 p-3 rounded-lg">Painpoints</div>
        {currentJourney.phase.map((phase, phaseIndex) => (
          <div key={phaseIndex} className="flex flex-col gap-1 w-80 bg-red-50 p-3 rounded-lg hover:opacity-80 hover:cursor-pointer">
            {phase.painpoints && phase.painpoints.map((painPoint, index) => (
              <div key={index} className="text-sm px-3 py-2 bg-white rounded-md shadow-sm">
                {truncateText(painPoint.single_painpoint, 40)}
              </div>
            ))}
            <button 
              onClick={() => openDrawer('Painpoint', phaseIndex)} 
              className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
            >
              + Add Painpoint
            </button>
          </div>
        ))}
        </div>

        <div className="flex min-w-max gap-1 mt-2">
        <div className="min-w-40 bg-green-50 p-3 rounded-lg">Gains</div>
        {currentJourney.phase.map((phase, phaseIndex) => (
          <div key={phaseIndex} className="flex flex-col gap-1 w-80 bg-green-50 p-3 rounded-lg">
            {phase.gains && phase.gains.map((gain, index) => (
              <div key={index} className="text-sm px-3 py-2 bg-white hover:opacity-80 hover:cursor-pointer rounded-md shadow-sm" title={gain.single_gain}>
                {truncateText(gain.single_gain, 40)}
              </div>
            ))}
            <button 
              onClick={() => openDrawer('Gain', phaseIndex)} 
              className="opacity-0 hover:opacity-100 mt-2 p-2 text-sm text-neutral-900 bg-neutral-200 rounded border border-dashed border-neutral-400"
            >
              + Add Gain
            </button>
          </div>
        ))}
        </div>
      </div>

      <JourneyItemDrawer
        isOpen={isDrawerOpen}
        onClose={closeDrawer}
        onSave={handleSaveItem}
        itemType={drawerType}
        phaseIndex={activePhaseIndex}
      />
    </div>
  );
}

export default CustomerJourneyTimeline;