import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import { ChevronLeftIcon, ChevronRightIcon, UserIcon, PlusIcon } from '@heroicons/react/20/solid';
import ContentHeader from '../Atoms/contentHeader';

const title = 'Personas';
const description = 'Navigate through the list to view specific information or to make updates to existing personas.';
const primaryAction = 'Create New Persona';

const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <UserIcon className="mx-auto h-12 w-12 text-gray-400" />
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Create First Personas</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new persona. Here soon will be cool marketing material.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Persona
      </button>
    </div>
  </div>
);

function PersonasTable() {
  const [people, setPeople] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const ITEMS_PER_PAGE = 24;
  const [personas, setPersonas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        console.log('Fetching personas for userId:', userId);


        const avatarUrl = personas && personas.avatar
          ? `https://panel.reislo.com/assets/${personas.avatar}?width=300&height=300&fit=cover&format=webp`
          : "https://thispersondoesnotexist.com/";


        const response = await axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}`, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: { _eq: userId } }, // Ensure correct filtering
            limit: ITEMS_PER_PAGE,
            page: currentPage,
            sort: '-date_created',
            fields: '*.*,project.project_name', // Include project name
            meta: 'total_count,filter_count'
          }
        });
        console.log('API Response:', response.data);

        setPeople(response.data.data.map(person => ({
          id: person.id,
          name: person.name,
          project: person.project?.project_name, // Access project name
          gender: person.gender,
          status: person.status,
          date: formatDate(person.date_created),
          image: person.avatar && person.avatar.id ? `https://panel.reislo.com/assets/${person.avatar.id}?width=256&height=256&fit=cover&format=webp` : 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        })));

        const totalCount = response.data.meta.filter_count; // Correctly use filter_count for filtered results
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching personas:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, token, userId]);

  const SkeletonRow = () => (
    <div className="w-80 h-80 bg-secondary-100 rounded-xl  animate-pulse"></div>
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleClick = (id, status) => {
    if (status === 'complete') {
      navigate(`/personas/${id}`);
    }
  };

  return (
    <div className="lg:pl-24">

      <div className="max-w-7xl mx-auto">
        {people.length > 0 && !loading && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={() => navigate('/personas/create-persona')}
          />
        )}
        <section className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-16">
          {loading ? (
            Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
              <SkeletonRow key={index} />
            ))
          ) : people.length === 0 ? (
            <div className="col-span-full h-full flex items-center justify-center">
              <EmptyState onPrimaryClick={() => navigate('/personas/create-persona')} />
            </div>
          ) : (
            people.map((person) => (
              <li
                key={person.id}
                onClick={() => handleClick(person.id, person.status)}
                className={`col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center border border-secondary-100 ${person.status === 'ongoing' ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:shadow-md'
                  }`}
              >
                <div className="flex flex-1 flex-col p-8">
                  <img className="mx-auto h-32 w-32 flex-shrink-0 rounded-full" src={person.image} alt="" />
                  <h3 className="mt-6 text-sm font-medium text-gray-900">{person.name}</h3>
                  <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <dd className="text-sm text-gray-500">{person.project}</dd>
                    {person.status === 'ongoing' && (
                      <dd className="mt-3">
                        <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                          Ongoing
                        </span>
                      </dd>
                    )}
                  </dl>
                </div>
              </li>
            ))
          )}
        </section>
        {people.length > 0 && (
          <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                Previous
              </button>
              <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, people.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      aria-current={currentPage === i + 1 ? 'page' : undefined}
                      onClick={() => handlePageChange(i + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === i + 1 ? 'z-10 bg-primary-500 text-primary-500' : 'text-gray-500 hover:bg-primary-50'}`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button onClick={() => handlePageChange(currentPage + 1)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default PersonasTable;
