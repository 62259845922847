import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const parseQualityReportToHTML = (reportArray) => {
  if (!Array.isArray(reportArray) || reportArray.length === 0) {
    return <p>Ensure that your article is well formatted and SEO friendly.</p>;
  }

  const report = reportArray[0].quality_control_report;

  const formatValue = (key, value) => {
    if (key === 'counts' && typeof value === 'object') {
      return (
        <div className="">
          {Object.entries(value).map(([k, v]) => (
            <span className="inline-block bg-neutral-50 rounded-full p-2 px-3 mr-1 mb-2 " key={k}>{`${k}: ${v}`}</span>
          ))}
        </div>
      );
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return value;
  };

  return (
    <div>
      {Object.entries(report).map(([key, value]) => (
        <div key={key} className="mb-4 border-b border-gray-300 pb-2">
          <h3 className="font-medium text-base flex items-center">
            {key}
            {value.description && (
              <div className="relative group">
                <QuestionMarkCircleIcon className="ml-2 h-5 w-5 text-gray-400 cursor-pointer" />
                <div className="absolute bottom-full mb-2 hidden w-64 text-sm leading-5 text-gray-900 bg-white border border-gray-200 rounded-md shadow-lg group-hover:block">
                  <div className="p-3 space-y-2">
                    <div className="font-semibold text-lg">Description</div>
                    <div>{value.description}</div>
                  </div>
                </div>
              </div>
            )}
          </h3>
          {Object.entries(value).map(([subkey, subvalue]) => (
            subkey !== 'description' && (
              <p className="text-sm opacity-80" key={subkey}>
                <span className="font-medium">{subkey.replace(/([A-Z])/g, ' $1').trim()}: </span>
                {formatValue(subkey, subvalue)}
              </p>
            )
          ))}
        </div>
      ))}
    </div>
  );
};

export default parseQualityReportToHTML;