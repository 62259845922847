import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { DIRECTUS_HEATMAPS_ENDPOINT } from '../../api';
import ContentHeader from '../Atoms/contentHeader';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/20/solid';

const statuses = {
  published: 'text-green-700 bg-green-50 ring-green-600/20',
  draft: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}


// EmptyState component
const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <svg
      className="mx-auto h-16 w-16 text-primary-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Let's start with a new heatmap</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new heatmap. Here soon will be cool marketing material.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Heatmap
      </button>
    </div>
  </div>
);

// Heatmap skeleton loader component
const HeatmapSkeleton = () => (
  <div className="flex items-center justify-between gap-x-6 my-5 animate-pulse bg-secondary-100">
    <div className="min-w-0 ">
      <div className="h-4 bg-secondary-100 rounded w-3/4 mb-4"></div>
      <div className="h-4 bg-secondary-100 rounded w-1/2 mb-2"></div>
    </div>
    <div className="h-10 w-10 bg-secondary-100 rounded-full"></div>
  </div>
);

function HeatmapsTable() {
  const [heatmaps, setHeatmaps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const title = 'Heatmaps';
  const description = 'Navigate through the cards to view specific information or to make updates to existing heatmaps.';
  const primaryAction = 'Create New Heatmap';
  const itemsPerPage = 50;

  const primaryClick = () => navigate('/predictions/create-heatmap');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(DIRECTUS_HEATMAPS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { user_created: userId },
            sort: '-date_created',
            page: currentPage,
            limit: itemsPerPage,
          }
        });
        setHeatmaps(response.data.data);
        setTotalPages(Math.ceil(response.data.meta.total_count / itemsPerPage));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching heatmaps:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token, currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="lg:pl-24 pr-8">
      <div className="max-w-7xl mx-auto">
        {heatmaps.length > 0 && !loading && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={primaryClick}
          />
        )}

        <div className="mt-8">
          {loading ? (
            <div className="divide-y divide-gray-100">
              {Array.from({ length: 6 }).map((_, index) => (
                <HeatmapSkeleton key={index} />
              ))}
            </div>
          ) : heatmaps.length === 0 ? (
            <div className="col-span-full h-full flex items-center justify-center">
              <EmptyState onPrimaryClick={primaryClick} />
            </div>
          ) : (
            <>
              <ul role="list" className="divide-y divide-gray-100">
                {heatmaps.map((heatmap) => (
                  <li key={heatmap.id} className="flex items-center justify-between gap-x-6 py-5 hover:bg-gray-50 transition duration-150 ease-in-out">
                    <div className="min-w-0">
                      <div className="flex items-start gap-x-3">
                        <p className="text-base font-semibold leading-6 text-gray-900">{heatmap.title}</p>
                        <p
                          className={classNames(
                            statuses[heatmap.status] || 'text-gray-600 bg-gray-50 ring-gray-500/10',
                            'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                          )}
                        >
                          {heatmap.status || 'N/A'}
                        </p>
                      </div>
                      <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">
                        <p className="whitespace-nowrap">
                          Created on <time dateTime={heatmap.date_created}>{new Date(heatmap.date_created).toLocaleDateString()}</time>
                        </p>
                        <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current mx-2">
                          <circle cx={1} cy={1} r={1} />
                        </svg>
                        <p className="truncate">Owner: {heatmap.owner || 'N/A'}</p>
                      </div>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <Link
                        to={`/predictions/${heatmap.id}`}
                        className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                      >
                        View heatmap<span className="sr-only">, {heatmap.heatmap_name}</span>
                      </Link>
                      <Menu as="div" className="relative flex-none">
                        <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                          <span className="sr-only">Open options</span>
                          <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href={`/predictions/${heatmap.id}`}
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  Edit<span className="sr-only">, {heatmap.heatmap_name}</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/predictions/move"
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  Move<span className="sr-only">, {heatmap.heatmap_name}</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/predictions/delete"
                                  className={classNames(
                                    active ? 'bg-gray-50' : '',
                                    'block px-3 py-1 text-sm leading-6 text-gray-900'
                                  )}
                                >
                                  Delete<span className="sr-only">, {heatmap.heatmap_name}</span>
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing <span className="font-medium">{(currentPage - 1) * itemsPerPage + 1}</span> to{' '}
                      <span className="font-medium">{Math.min(currentPage * itemsPerPage, heatmaps.length)}</span> of{' '}
                      <span className="font-medium">{heatmaps.length}</span> results
                    </p>
                  </div>
                  <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Previous</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clipRule="evenodd" />
                        </svg>
                      </button>
                      {[...Array(totalPages).keys()].map((page) => (
                        <button
                          key={page + 1}
                          onClick={() => handlePageChange(page + 1)}
                          className={classNames(
                            page + 1 === currentPage
                              ? 'relative z-10 inline-flex items-center bg-primary-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
                              : 'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0',
                            'hidden md:inline-flex'
                          )}
                        >
                          {page + 1}
                        </button>
                      ))}
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      >
                        <span className="sr-only">Next</span>
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HeatmapsTable;