import React, { useEffect, useState } from 'react';
import { ArrowPathIcon, ChartBarIcon, CloudArrowUpIcon, LockClosedIcon, UserIcon } from '@heroicons/react/24/solid';

const features = [
  {
    name: 'Create UX Personas',
    description: 'Begin your design process effectively by creating initial personas. ',
    icon: UserIcon,
    link: '/personas/create-persona',
    bgColor: 'bg-white',
    iconBgColor: 'bg-red-50',
    iconColor: 'text-red-400'
  },
  {
    name: 'User Journey Map',
    description: 'Map out the path your users take with our User Journey Mapping tool. ',
    icon: ChartBarIcon,
    link: '/user-journeys',
    bgColor: 'bg-white',
    iconBgColor: 'bg-orange-100',
    iconColor: 'text-orange-400'
  },
  {
    name: 'Explore Insights',
    description: 'Discover AI Analysis Insights based on your Journeys, Personas and Datasets.',
    icon: LockClosedIcon,
    link: '/personas/create-persona',
    bgColor: 'bg-white',
    iconBgColor: 'bg-primary-100',
    iconColor: 'text-primary-400'
  }
];

const posts = [
  {
    id: 1,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },
  },

  {
    id: 2,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },


  },

  {
    id: 3,
    title: 'Boost your conversion rate',
    href: '#',
    description: 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
    imageUrl: 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    author: {
      name: 'Michael Foster',
      imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
    },


  },

  // More posts can be added here...
];

const Dashboard = () => {
  const [name, setName] = useState(() => localStorage.getItem('username') || '');

  useEffect(() => {
    const storedName = localStorage.getItem('username');
    if (storedName) {
      setName(storedName);
    }
  }, []);

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <div className="mb-10 mt-24">
          <h1 className="text-3xl mb-0 font-medium">Hello, {name}</h1>
          <h2 className="text-xl mt-0 text-neutral-800">What would you like to do today?</h2>
        </div>
        <div className="grid max-w-xl gap-x-6 lg:max-w-none grid-cols-12 mt-8">
          {features.map((feature, index) => (
            <a key={index} href={feature.link} className={`relative col-span-4 ${feature.bgColor}  border border-neutral-200 hover:shadow text-center hover:cursor-pointer p-6 rounded-xl`}>
              <div className="flex flex-col gap-x-6 items-center px-8 py-5">
                <div className={`p-5 rounded-xl ${feature.iconBgColor} mb-4`}>
                  {<feature.icon className={`h-8 w-8 ${feature.iconColor}`} aria-hidden="true" />}
                </div>
                <div>
                  <h3 className="text-xl font-medium mt-3">{feature.name}</h3>
                  <dd className="mt-2 text-base leading-7 text-gray-800 opacity-80">{feature.description}</dd>
                </div>
              </div>
            </a>
          ))}
        </div>

        <div className="mb-8 mt-24 hidden">
          <div className="">
            <h1 className="text-2xl mb-0 font-medium">Knowledge & Insights</h1>
            <h2 className="text-base mt-0 text-neutral-600">Power up your User Experience Management with Insights</h2>
          </div>
          <div className="mx-auto grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 mt-6 mb-32 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                <div className="flex flex-wrap items-center gap-y-1 overflow-hidden text-sm leading-6 text-gray-300">
                  <time dateTime={post.datetime} className="mr-8">
                    {post.date}
                  </time>
                  <div className="-ml-4 flex items-center gap-x-4">
                    <svg viewBox="0 0 2 2" className="-ml-0.5 h-0.5 w-0.5 flex-none fill-white/50">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <div className="flex gap-x-2.5">
                      <img src={post.author.imageUrl} alt="" className="h-6 w-6 flex-none rounded-full bg-white/10" />
                      {post.author.name}
                    </div>
                  </div>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
