import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT } from '../../api'; // Ensure this is correctly imported
import { UserIcon, PlusIcon } from '@heroicons/react/20/solid';

const title = 'User Journeys';
const description = 'This table provides a quick snapshot of each persona\'s details, allowing you to easily manage and review their profiles. Navigate through the list to view specific information or to make updates to existing personas.';
const primaryAction = 'Create New User Journey';

const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <UserIcon className="mx-auto h-12 w-12 text-gray-400" />
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Create First User Journey</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new user journey. Here soon will be cool marketing material.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New User Journey
      </button>
    </div>
  </div>
);

function ProjectsTable() {
  const [projects, setProjects] = useState([]); // State for storing projects data
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id'); // Assuming you store user ID in local storage

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(DIRECTUS_USER_JOURNEYS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userId }, // Adjust this if your Directus schema uses a different field name
          }
        });
        setProjects(response.data.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchData();
  }, [userId, token]); // Ensure dependencies are correctly listed for re-fetching when they change

  return (
    <div className="px-4 sm:px-0 lg:pl-24">
      <div className="max-w-7xl mx-auto">
        {projects.length > 0 && (
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto mt-8">
              <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">{title}</h1>
              <p className="mt-6 text-base text-gray-600">
                {description}
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                onClick={() => navigate('/user-journeys/create')}
                className="block rounded-full bg-primary-500 px-6 py-2 text-center text-base font-medium text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                {primaryAction}
              </button>
            </div>
          </div>
        )}
        <div className="mt-8 flow-root">
          <div className="mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {/* If no journeys are found, display an empty state */}
              {projects.length === 0 ? (
                <EmptyState onPrimaryClick={() => navigate('/user-journeys/create')} />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User Journey Name</th>
                      <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Short Description</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {projects.map((userjourney) => (
                      <tr key={userjourney.id} onClick={() => navigate(`/user-journeys/${userjourney.id}`)} className="cursor-pointer hover:bg-gray-100">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">{userjourney.user_journey_name}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{userjourney.short_description}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectsTable;
