import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import Snackbar from '../Atoms/snackbar';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  LinkIcon,
  PlusIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { UserPlusIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    project_name: '',
    short_description: '',
    big_picture: '',
    product_service_details: '',
    identificator: '',
    goals: '',
    personas: [],
    industry_type: '',
    industry: '',
  });

  const [personasDetails, setPersonasDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPersonas, setLoadingPersonas] = useState(true);
  const [error, setError] = useState('');
  const token = localStorage.getItem('directus_token');

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProject(response.data.data);
        await fetchPersonas(response.data.data.personas);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch project details.');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id, token]);

  const fetchPersonas = async (personaIds) => {
    setLoadingPersonas(true);
    try {
      const responses = await Promise.all(personaIds.map((personaId) =>
        axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${personaId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ));
      setPersonasDetails(responses.map((response) => response.data.data));
      setLoadingPersonas(false);
    } catch (error) {
      setError('Failed to fetch personas details.');
      setLoadingPersonas(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject((prev) => ({ ...prev, [name]: value }));
  };

  const saveProject = async () => {
    setLoading(true);
    try {
      const response = await axios.patch(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, project, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setShowSnackbar(false);
        setSnackbarMessage('Project has been saved properly (remove this later)');
        setActionName('Close');
        setSnackbarType('success');
        setTimeout(() => setShowSnackbar(true), 0);
      } else {
        alert('Failed to update persona: Unexpected status code');
      }
    } catch (error) {
      alert('Failed to update project');
    } finally {
      setLoading(false);
    }
  };

  const handlePersonaClick = (personaId) => {
    navigate(`/personas/${personaId}`);
  };

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            actionName={actionName}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}

        <div className="pt-8 lg:flex lg:justify-between bg-white pr-8">

          
          <div className="min-w-0 flex-1">
            {loading ? (
              <div className="animate-pulse">
                <div className="h-12 bg-gray-300 rounded w-3/4 mb-4"></div>
                <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
              </div>
            ) : (
              <>
              
                <input
                  type="text"
                  name="project_name"
                  value={project.project_name}
                  onChange={handleInputChange}
                  className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight w-full shadow-none bg-transparent border-none focus:outline-none"
                />
                <div className="pl-3 mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {project.industry_type}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <BuildingOfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {project.industry}
                  </div>
                </div>
                <div className="mt-4">
                <div className="col-span-12 p-5 bg-white border border-secondary-100 rounded-xl w-full">

                  <p className="mt-0 text-base text-gray-600 mb-8 w-full">
                    <textarea
                      name="short_description"
                      value={project.short_description}
                      onChange={handleInputChange}
                      className="w-full personas-details-ghost-inputs"
                    />
                  </p>
                  </div>
                </div>
              </>
            )}
          </div>

         
        </div>

        <div>
          <div className="grid grid-cols-12 mt-0 gap-8 pr-8">
            {loading ? (
              <>
                <div className="col-span-6 mt-12 bg-white  rounded-xl animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-28 bg-gray-300 rounded mb-4"></div>
                </div>
                <div className="col-span-6 mt-12 bg-white  rounded-xl animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-28 bg-gray-300 rounded mb-4"></div>
                </div>
                <div className="col-span-6 mt-12 bg-white  rounded-xl animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-16 bg-gray-300 rounded mb-4"></div>
                </div>
                <div className="col-span-6 mt-12 bg-white  rounded-xl animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-16 bg-gray-300 rounded mb-4"></div>
                </div>

              </>
            ) : (
              <>
                <div className="col-span-6 p-5 bg-white border border-secondary-100 rounded-xl">
                  <h2 className="text-2xl font-medium mb-4">Big Picture</h2>
                  <textarea
                    name="big_picture"
                    value={project.big_picture}
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs p-0"
                    rows="6"
                  />
                </div>
                <div className="col-span-6 p-5 bg-white border-secondary-100 border rounded-xl">
                  <h2 className="text-2xl font-medium mb-4">Services Details</h2>
                  <textarea
                    name="product_service_details"
                    value={project.product_service_details}
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs overflow-y-auto p-0"
                    rows="6"
                  />
                </div>
                <div className="col-span-6 p-5 bg-white border-secondary-100 border rounded-xl">
                  <h2 className="text-2xl font-medium mb-4">Goals</h2>
                  <textarea
                    name="goals"
                    value={project.goals}
                    placeholder="What would you like to achieve?"
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs p-0"
                    rows="3"
                  />
                </div>

                <div className="col-span-6 p-5 bg-white border-secondary-100 border rounded-xl">
                  <h2 className="text-2xl font-medium mb-4">Competitors</h2>
                  <textarea
                    name="competitors"
                    value={project.competitors}
                    placeholder="Your main competitors"
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs p-0"
                    rows="3"
                  />
                </div>
              </>
            )}
          </div>

          <div className="mb-8 mt-16">
            <div className="mb-4">
              <h2 className="text-3xl font-medium mt-8">Personas</h2>
              <p className="text-base text-gray-600 mt-0">List of personas assigned to your project</p>
            </div>
            {loadingPersonas ? (
              <div className="grid grid-cols-3 gap-4">
                {Array.from({ length: 3 }).map((_, index) => (
                  <div key={index} className="p-4 bg-white shadow rounded-lg animate-pulse">
                    <div className="h-4 bg-gray-300 rounded w-3/4 mb-4"></div>
                    <div className="h-4 bg-gray-300 rounded w-1/2 mb-2"></div>
                  </div>
                ))}
              </div>
            ) : personasDetails.length > 0 ? (
              <ul className="grid grid-cols-3 gap-4">
                {personasDetails.map((persona) => (
                  <li
                    key={persona.id}
                    onClick={() => handlePersonaClick(persona.id)}
                    className="cursor-pointer bg-white hover:shadow-lg transition-shadow duration-200 text-lg p-4 rounded-lg border border-gray-200"
                  >
                    <div className="flex justify-between">
                      <div className="font-medium">{persona.name}</div>
                      <div className="text-sm">{persona.type}</div>
                    </div>
                    <div className="text-sm text-gray-600">{persona.occupation}</div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-center text-gray-600 mt-4">
                <p>No personas assigned to this project yet.</p>
                <button
                  type="button"
                  onClick={() => navigate('/personas/createPersona')}
                  className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 mt-4"
                >
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  New Persona
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;