import axios from 'axios';
import axiosRetry from 'axios-retry';

export const DIRECTUS_INSTANCE = 'https://panel.reislo.com';

export const DIRECTUS_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/heatmaps`;

const REACT_APP_GPT_API_KEY = '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';
const SEO_ASSISTANT_ID = 'asst_0ui8Nz9JSXofVHtT4GtcYAIp';

export const apiHeaders = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`,
  'OpenAI-Beta': 'assistants=v2'
};

// Setup axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry to automatically retry requests
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

directusAPI.interceptors.request.use(config => {
  const token = localStorage.getItem('directus_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => Promise.reject(error));

directusAPI.interceptors.response.use(response => response, handleApiError);

function handleApiError(error) {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
}

// Fetch heatmap by ID
export const fetchHeatmapById = async (id) => {
  try {
    const response = await directusAPI.get(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`);
    return response.data.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Save or update heatmap data
export const saveHeatmap = async (id, heatmapData) => {
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found. Please log in.');
  }

  const headers = {
    'Authorization': `Bearer ${token}`
  };

  // Check if there is a coordinates_grid_screenshot to upload
  if (heatmapData.coordinates_grid_screenshot && heatmapData.coordinates_grid_screenshot instanceof File) {
    const formData = new FormData();
    formData.append('name', heatmapData.name || '');
    formData.append('evaluation', heatmapData.evaluation || '');
    formData.append('coordinates_grid_screenshot', heatmapData.coordinates_grid_screenshot);

    headers['Content-Type'] = 'multipart/form-data';

    try {
      const response = await axios.patch(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`, formData, { headers });
      return response.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  } else {
    // No coordinates_grid_screenshot to upload, use JSON data
    const body = {
      name: heatmapData.name || '',
      evaluation: heatmapData.evaluation || '',
      coordinates_grid_screenshot: heatmapData.coordinates_grid_screenshot,
    };

    try {
      const response = await axios.patch(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`, body, { headers });
      return response.data;
    } catch (error) {
      handleApiError(error);
      throw error;
    }
  }
};

// Evaluate heatmap data using OpenAI GPT-4
export const evaluateHeatmap = async (heatmap) => {
  const { screenshot, name, id } = heatmap;
  const userId = localStorage.getItem('user_id');
  const apiKey = REACT_APP_GPT_API_KEY;

  const validImageUrl = `${screenshot}.png`; // Ensure it ends with a valid extension

  console.log('Constructed Image URL:', validImageUrl); // Log the constructed URL for debugging

  const messages = [
    {
      role: 'assistant',
      content: 'I am UX Expert and my job is to conduct Heuristic Evaluation. I will prepare a comprehensive, professional and very detailed with specific advices evaluation of the screenshot of the digital product.'
    },
    {
      role: 'user',
      content: 'During the evaluation please do search throught the files you have access find sources to specific highlited issues or areas.'
    },
    {
      role: 'user',
      content: [
        { type: 'text', text: 'Evaluate only the screenshot, without any asumptions what is in next steps. Focus on visual analysis, simplified heuristic evaluation, content evaluation, and accessibility. Provide specific, actionable recommendations.' },
        {
          type: 'image_url',
          image_url: {
            url: validImageUrl,
            detail: 'high'
          }
        }
      ]
    },

    {
      role: 'user',
      content:
        'Response prepare in pure JSON without any comments outside brackets. Please cover all features you can see on the screen. Format it without ANY comments outside of brackets in the following scheme:\n' +
        '[\n' +
        '  {\n' +
        '    "Feature": "Name of the feature or element",\n' +
        '     "Category": "Visual Design | Layout | Content | Accessibility | Usability",\n' +
        '    "Status": "Good | Needs Improvement | Critical",\n' +
        '    "Observation": "Detailed description of what you see and any issues",\n' +
        '    "Recommendation": "Specific, actionable suggestion for improvement",\n' +
        '    "Priority": "High | Medium | Low",\n' +
        '  }\n' +
        ']\n'
    }
  ];

  try {
    console.log('Creating a new thread for evaluation...');
    const threadRes = await axios.post('https://api.openai.com/v1/threads', {}, { headers: apiHeaders });
    console.log('Thread response:', threadRes.data);
    if (!threadRes.data || !threadRes.data.id) {
      throw new Error('Failed to create thread');
    }
    const threadId = threadRes.data.id;

    for (const message of messages) {
      console.log('Sending message to thread:', threadId, message);
      const messageRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/messages`, message, { headers: apiHeaders });
      console.log('Message response:', messageRes.data);
      if (!messageRes.data) {
        throw new Error('Failed to add message');
      }
    }

    console.log('Running the assistant...');
    const runRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/runs`, {
      assistant_id: SEO_ASSISTANT_ID,
      tool_resources: {
        file_search: {
          vector_store_ids: ['vs_yN16ml89jkatIoZ70D3Qf2PN'] // Replace with your actual vector store ID
        }
      }

    }, {
      headers: apiHeaders
    });

    console.log('Run response:', runRes.data);
    if (!runRes.data || !runRes.data.id) {
      throw new Error('Failed to run assistant');
    }
    const runId = runRes.data.id;

    let runData;
    while (true) {
      runData = await checkRunStatus(threadId, runId);
      if (runData.status === 'completed') {
        break;
      } else if (runData.status === 'failed') {
        console.error('Assistant run failed:', runData);
        throw new Error('Assistant run failed.');
      }
      await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds before checking again
    }

    console.log('Fetching messages from thread:', threadId);
    const messagesRes = await listMessages(threadId);
    console.log('Messages response:', messagesRes.data);

    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const content = assistantMessage.content[0].text.value;

    // Save the evaluation response to Directus
    await saveEvaluationToDirectus(id, content);

    return content;
  } catch (error) {
    console.error('Error evaluating heatmap:', error);
    throw error;
  }
};

// Function to save the evaluation result to Directus
const saveEvaluationToDirectus = async (id, evaluation) => {
  console.log('Saving evaluation data:', evaluation);
  const token = localStorage.getItem('directus_token');
  if (!token) {
    throw new Error('Authentication token not found. Please log in.');
  }

  const body = { evaluation };

  try {
    const response = await axios.patch(`${DIRECTUS_HEATMAPS_ENDPOINT}/${id}`, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const checkRunStatus = async (threadId, runId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, { headers: apiHeaders });
    console.log('Check run status response:', res.data);
    return res.data;
  } catch (error) {
    console.error('Error checking run status:', error);
    throw error;
  }
};

export const listMessages = async (threadId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, { headers: apiHeaders });
    console.log('List messages response:', res.data);
    return res.data;
  } catch (error) {
    console.error('Error listing messages:', error);
    throw error;
  }
};