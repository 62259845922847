import React, { Fragment } from 'react';
import { BellIcon } from '@heroicons/react/24/outline';

const ProfileDropdown = ({ userNavigation, classNames }) => {
  return (<button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
    <span className="sr-only">View notifications</span>
    <BellIcon className="h-6 w-6" aria-hidden="true" />
  </button>
  );
};

export default ProfileDropdown;
