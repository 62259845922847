import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { DIRECTUS_PROJECTS_ENDPOINT } from '../../api';
import ContentHeader from '../Atoms/contentHeader';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, PlusIcon } from '@heroicons/react/20/solid';

const statuses = {
  published: 'text-green-700 bg-green-50 ring-green-600/20',
  draft: 'text-gray-600 bg-gray-50 ring-gray-500/10',
  archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// EmptyState component
const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center mt-48">
    <svg
      className="mx-auto h-16 w-16 text-primary-400"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        vectorEffect="non-scaling-stroke"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
      />
    </svg>
    <h3 className="mt-2 text-2xl font-semibold text-gray-900">Let's start with new project</h3>
    <p className="mt-1 text-sm text-gray-500">Get started by creating a new persona. Here soon will be cool marketing material.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New Project
      </button>
    </div>
  </div>
);

// Project skeleton loader component
const ProjectSkeleton = () => (
  <div className="flex items-center justify-between gap-x-6 my-5 animate-pulse bg-secondary-100">
    <div className="min-w-0 ">
      <div className="h-4 bg-secondary-100 rounded w-3/4 mb-4"></div>
      <div className="h-4 bg-secondary-100 rounded w-1/2 mb-2"></div>
    </div>
    <div className="h-10 w-10 bg-secondary-100 rounded-full"></div>
  </div>
);

function ProjectsTable() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const title = 'Projects';
  const description = 'Navigate through the cards to view specific information or to make updates to existing projects.';
  const primaryAction = 'Create New Project';

  const primaryClick = () => navigate('/projects/create-project');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            filter: { owner: userId },
          }
        });
        setProjects(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching projects:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, token]);

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        {projects.length > 0 && !loading && (
          <ContentHeader
            title={title}
            description={description}
            primaryAction={primaryAction}
            onPrimaryAction={primaryClick}
          />
        )}

        <div className="mt-8">
          {loading ? (
            <div className="divide-y divide-gray-100">
              {Array.from({ length: 6 }).map((_, index) => (
                <ProjectSkeleton key={index} />
              ))}
            </div>
          ) : projects.length === 0 ? (
            <div className="col-span-full h-full flex items-center justify-center">
              <EmptyState onPrimaryClick={primaryClick} />
            </div>
          ) : (
            <ul role="list" className="divide-y divide-gray-100">
              {projects.map((project) => (
                <li key={project.id} className="flex items-center justify-between gap-x-6 py-5 ">
                  <div className="min-w-0">
                    <div className="flex items-start gap-x-3">
                      <p className="text-base font-semibold leading-6 text-gray-900">{project.project_name}</p>
                      <p
                        className={classNames(
                          statuses[project.status] || 'text-gray-600 bg-gray-50 ring-gray-500/10',
                          'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset'
                        )}
                      >
                        {project.status || 'N/A'}
                      </p>
                    </div>
                    <div className="mt-1 flex items-center gap-x-2 text-sm leading-5 text-gray-500">
                      <p className="whitespace-nowrap">
                        Created on <time dateTime={project.date_created}>{new Date(project.date_created).toLocaleDateString()}</time>
                      </p>
                      <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current mx-2">
                        <circle cx={1} cy={1} r={1} />
                      </svg>
                      <p className="truncate">Owner: {project.owner || 'N/A'}</p>
                    </div>
                  </div>
                  <div className="flex flex-none items-center gap-x-4">
                    <Link
                      to={`/projects/${project.id}`}
                      className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                    >
                      View project<span className="sr-only">, {project.project_name}</span>
                    </Link>
                    <Menu as="div" className="relative flex-none">
                      <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                      </Menu.Button>
                      <Transition
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                          <Menu.Item>
                            {({ focus }) => (
                              <a
                                href={`/projects/${project.id}`}
                                className={classNames(
                                  focus ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                Edit<span className="sr-only">, {project.project_name}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ focus }) => (
                              <a
                                href="/projects/move"
                                className={classNames(
                                  focus ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                Move<span className="sr-only">, {project.project_name}</span>
                              </a>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ focus }) => (
                              <a
                                href="/projects/delete"
                                className={classNames(
                                  focus ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                Delete<span className="sr-only">, {project.project_name}</span>
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectsTable;