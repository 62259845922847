import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Snackbar from '../Atoms/snackbar';
import { format, parseISO } from 'date-fns';
import { DIRECTUS_PERSONAS_ENDPOINT } from '../../api';

import {
  CloudArrowUpIcon,
  ArrowLeftIcon,
  EllipsisVerticalIcon,
  FaceSmileIcon,
  PhotoIcon,
  FolderArrowDownIcon,
  TrashIcon,
  ArrowPathIcon,
  PresentationChartBarIcon,
} from '@heroicons/react/24/outline';


function PersonasDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [personas, setPersonas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const token = localStorage.getItem('directus_token');
  const autosaveTimeoutRef = useRef(null);

  const editorRefs = useRef({
    biography: null,
    motivations: null,
    needs: null,
    frustrations: null,
  });

  const goBack = () => {
    navigate('/personas');
  };

  const fetchPersonas = async () => {
    try {
      const response = await axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Raw data:", response.data);

      const data = response.data.data;
      setPersonas(data);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching persona details:', error);
      setError('Failed to fetch persona details.');
      setLoading(false);
    }
  };

  const initializeEditors = useCallback(() => {
    ['biography', 'motivations', 'needs', 'frustrations'].forEach((field) => {
      if (!editorRefs.current[field]) {
        const editorData = typeof personas[field] === 'string' ? JSON.parse(personas[field]) : personas[field];
        editorRefs.current[field] = new EditorJS({
          holder: `${field}-editor`,
          data: editorData,
          minHeight: 0,
          tools: {
            header: {
              class: Header,
              inlineToolbar: ['link'],
              config: {
                placeholder: 'Enter a header',
                levels: [2, 3],
                defaultLevel: 2
              }
            },
            paragraph: {
              class: Paragraph,
              inlineToolbar: true
            },
            list: {
              class: List,
              inlineToolbar: true
            },
          },
          onReady: () => {
            console.log(`${field} editor is ready`);
          },
          onChange: () => {
            if (autosaveTimeoutRef.current) {
              clearTimeout(autosaveTimeoutRef.current);
            }
            autosaveTimeoutRef.current = setTimeout(() => handleEditorChange(field), 2000);
          }
        });
      }
    });
  }, [personas]);

  useEffect(() => {
    fetchPersonas();
  }, [id, token]);

  useEffect(() => {
    if (personas) {
      initializeEditors();
    }
  }, [personas, initializeEditors]);

  const handleEditorChange = async (field) => {
    if (editorRefs.current[field]) {
      try {
        const outputData = await editorRefs.current[field].save();
        const updatedPersonas = {
          ...personas,
          [field]: JSON.stringify(outputData),
          date_updated: new Date().toISOString()
        };
        setPersonas(updatedPersonas);
        await savePersonaData(updatedPersonas);
      } catch (error) {
        console.error(`Error saving editor content for ${field}:`, error);
      }
    }
  };

  const savePersonaData = async (dataToSave) => {
    try {
      const response = await axios.patch(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`, dataToSave, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      console.log("Received response:", response.data);

      if (response.status === 200) {
        setSnackbarMessage('Persona has been saved properly (remove it later)');
        setSnackbarType('success');
        setShowSnackbar(true);
        setTimeout(() => setShowSnackbar(false), 3000);
      } else {
        console.error('Unexpected status code:', response.status);
        alert('Failed to update persona: Unexpected status code');
      }
    } catch (error) {
      console.error('Error updating persona:', error);
      alert(`Failed to update persona: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const deletePersona = async () => {
    try {
      await axios.delete(`${DIRECTUS_PERSONAS_ENDPOINT}/${id}`);
      navigate('/personas');
    } catch (error) {
      console.error('Error deleting persona:', error);
      alert('Failed to delete persona.');
    }
  };

  if (error) return <div>{error}</div>;
  if (!personas) return <div>Loading...</div>;

  function formatDateUpdated(date) {
    if (!date) return "No update date provided";

    const now = new Date();
    const updatedDate = parseISO(date);

    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;

    if (diffInSeconds < 60) {
      return `${Math.floor(diffInSeconds)} seconds ago`;
    } else if (diffInSeconds < 3600) {
      return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    } else if (diffInSeconds < 86400) {
      return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    } else if (diffInSeconds < 604800) {
      return `${Math.floor(diffInSeconds / 86400)} days ago`;
    } else {
      return format(updatedDate, "dd MMM yyyy");
    }
  }

  const avatarUrl = personas && personas.avatar
    ? `https://panel.reislo.com/assets/${personas.avatar}?width=300&height=300&fit=cover&format=webp`
    : "https://thispersondoesnotexist.com/";

  return (
    <div className="pb-40 lg:pl-24">
      {showSnackbar && <Snackbar message={snackbarMessage} actionName={actionName} type={snackbarType} onClose={() => setShowSnackbar(false)} />}
      <div className="mx-auto max-w-7xl">
        <div className="flex gap-4 justify-between py-4 items-center mt-4">
          <div className="cursor-pointer" onClick={goBack}>
            <div className="bg-white border border-secondary-100 hover:shadow rounded-full p-3 flex items-center justify-center">
              <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
            </div>
          </div>
          <div className="flex gap-2 items-center cursor-pointer ">
            {loading ? (
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-secondary-500 hover:text-primary-500" role="status">
                <span className="visually-hidden"></span>
              </div>
            ) : (
              <CloudArrowUpIcon className="h-6 w-6 text-secondary-500 hover:text-primary-500" aria-hidden="true" />
            )} <span className="text-sm text-neutral-600">Saved {formatDateUpdated(personas.date_updated)}</span>
          </div>
          <div>
            <div className="cursor-pointer">
              <div className="bg-white border border-secondary-100 hover:shadow rounded-full p-3 flex items-center justify-center">
                <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="mx-auto pt-8 mt-0 bg-white rounded-lg">

        <div className="flex-col max-w-4xl mx-auto">
          <div className="justify-between ">
            <div className="flex flex-col mx-auto  w-full text-center items-center gap-8">
              <div className="relative group">
                <img
                  className="rounded-full w-48"
                  src={avatarUrl}
                  alt="Avatar"
                  onError={(e) => { e.target.onerror = null; e.target.src = "https://thispersondoesnotexist.com/"; }}
                />
                <div className="absolute inset-0 flex rounded-full  cursor-pointer items-center justify-center bg-primary-500 bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity">
                  <ArrowPathIcon className="h-12 w-12 text-white" aria-hidden="true" />
                </div>
              </div>
              <div className="flex flex-col">
                <h1 className="text-4xl">{personas.name}</h1>
                <h4 className="text-lg opacity-60">{personas.age} years old</h4>
              </div>

              <div className="flex justify-center">
                <div className="flex gap-4 ">
                  <span className="flex gap-2 h-min py-2 px-3  rounded-lg  bg-secondary-50 text-secondary-900  hover:text-primary-500 hover:bg-primary-100 hover:cursor-pointer text-sm"><FaceSmileIcon className="h-5 w-5" aria-hidden="true" />Emphaty Map</span>
                  <span className="flex gap-2 h-min py-2 px-3  rounded-lg  bg-secondary-50   text-secondary-900  hover:text-primary-500 hover:bg-primary-100 hover:cursor-pointer  text-sm"><PhotoIcon className="h-5 w-5" aria-hidden="true" />Moodboard</span>
                  <span className="flex gap-2 h-min py-2 px-3  rounded-lg  bg-secondary-50   text-secondary-900  hover:text-primary-500 hover:bg-primary-100 hover:cursor-pointer  text-sm"><PresentationChartBarIcon className="h-5 w-5" aria-hidden="true" />Customer Journey</span>
                  <span className="flex gap-2 h-min py-2 px-3  rounded-lg   bg-secondary-50   text-secondary-900  hover:text-primary-500 hover:bg-primary-100 hover:cursor-pointer  text-sm"><FolderArrowDownIcon className="h-5 w-5" aria-hidden="true" />Export</span>
                  <span className=" hidden flex gap-2 h-min py-2 px-3  rounded-lg   bg-primary-50   text-primary-900 hover:text-primary-500 hover:bg-primary-100 hover:cursor-pointer  text-sm"><TrashIcon className="h-5 w-5" aria-hidden="true" />Delete</span>
                </div>
                <button className="hidden" onClick={deletePersona}>
                  deletehere
                </button>
              </div>
            </div>

          </div>

          <div className="flex flex-col mt-8 mx-auto pt-8 ">
            <div className="">
              <h3 className="text-2xl">Personas Details</h3>
            </div>
            <div className="flex px-0 pt-4 gap-x-8">
              <div className="flex flex-col w-1/3">
                <div className="pb-4">
                  <label className="text-sm opacity-60">Education</label>
                  <h4 className="text-base">{personas.education}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Occupation</label>
                  <h4 className="text-base">{personas.occupation}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Income</label>
                  <h4 className="text-base">${personas.income} yearly</h4>
                </div>
              </div>
              <div className="flex flex-col w-1/3">
                <div className="pb-4">
                  <label className="text-sm opacity-60">Family Life Cycle</label>
                  <h4 className="text-base">{personas.family_life_cycle}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Family Size</label>
                  <h4 className="text-base">{personas.family_size}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Living</label>
                  <h4 className="text-base">{personas.country}, {personas.city}</h4>
                </div>
              </div>
              <div className="flex flex-col w-1/3">
                <div className="pb-4">
                  <label className="text-sm opacity-60">Type</label>
                  <h4 className="text-base">{personas.type}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Asigned to Project</label>
                  <h4 className="text-base">{personas.project}</h4>
                </div>
                <div className="pb-4">
                  <label className="text-sm opacity-60">Asigned to User Journey</label>
                  <h4 className="text-base">{personas.project}</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full mt-4">
            <div className="editable-content mb-8 border-secondary-50">
              <div id="biography-editor"></div>
            </div>
            <div className="editable-content mb-4 pt-4">
              <div id="motivations-editor"></div>
            </div>
            <div className="editable-content mb-4 pt-4">
              <div id="needs-editor"></div>
            </div>
            <div className="editable-content mb-4 pt-4">
              <div id="frustrations-editor"></div>
            </div>
            <div className="editable-content hidden mb-8 p-4 rounded-xl">
              <h3 className="text-2xl mb-2">MOSAIC Segmentation</h3>
            </div>
            <div className="editable-content mb-8 p-4 rounded-xl hidden">
              <h3 className="text-2xl mb-2">VALS Segmentation</h3>
            </div>
            <div className="editable-content mt-16 bg-grey-100 opacity-60 hidden">
              <p><strong>Prompt (Admin only):</strong> </p>
              <pre className="text-sm whitespace-pre-wrap">{JSON.stringify(JSON.parse(personas.prompt), null, 2)}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonasDetails;
