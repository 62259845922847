import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from './loader';
import { DIRECTUS_PROJECTS_ENDPOINT } from '../../api';

const PersonasForm = ({ handleSubmit }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projectDetails, setProjectDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [error, setError] = useState('');
  
  const [formData, setFormData] = useState({});
  const token = localStorage.getItem('directus_token'); // Retrieve the stored token
  const userId = localStorage.getItem('user_id'); // Assuming user_id is stored in local storage
  const navigate = useNavigate(); // Using useNavigate hook from react-router-dom

  const title = "Create Personas";
  const description = "Select your desired type - be it personas, buyer personas, or proto personas, and fill out the form below with precision. Accurate and detailed information ensures that your personas capture the essence of your target audience. The better your persona represents your user, the more effectively you can meet their unique needs and preferences, and create a more personalized user experience.";

  useEffect(() => {
    const fetchProjects = async () => {
      if (!token) {
        setError('Failed to fetch projects: No token found');
        return;
      }
      setLoading(true);
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: { filter: { owner: userId } }
        });
        setProjects(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(`Failed to fetch projects: ${error.response ? error.response.statusText : error.message}`);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [userId, token]);

  useEffect(() => {
    if (selectedProjectId) {
      const fetchProjectDetails = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${selectedProjectId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setProjectDetails(response.data.data);
          setLoading(false);
        } catch (error) {
          setError(`Failed to fetch project details: ${error.response ? error.response.statusText : error.message}`);
          setLoading(false);
        }
      };

      fetchProjectDetails();
    }
  }, [selectedProjectId, token]);

  const handleProjectChange = (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects'); // Navigate to project creation page
    } else {
      setSelectedProjectId(value);
    }
    setFormData({ ...formData, project: value }); // Correctly capture project selection
  };

  const handleInputChange = (event) => {
    const { name, type, value, files } = event.target;
    const actualValue = type === 'file' ? files[0] : value;
    console.log(`Updating form data: ${name} = ${actualValue}`); // Log the input changes for debugging
    setFormData({ ...formData, [name]: actualValue });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log('Final Form Data:', formData); // Log the final form data before submission
    try {
      await handleSubmit(formData);
      setSubmitStatus('success');
    } catch (err) {
      setError(err.message);
      setSubmitStatus('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="lg:pl-72 pb-32">
      <div className="sm:flex sm:items-center mb-16">
        <div className="sm:flex-auto mt-8 hidden">
          <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">{title}</h1>
          <p className="mt-6 text-base text-gray-600">{description}</p>
        </div>
      </div>

      {(isLoading || submitStatus) && (
        <div className="loader-container">
          <Loader isLoading={isLoading} submitStatus={submitStatus} />
        </div>
      )}

      {error && !isLoading && <div className="error-message">{error}</div>}
      <form onSubmit={submitForm}>
        <div className="grid grid-cols-12 gap-6 bg-white p-8 rounded-lg mb-6 max-w-5xl mx-auto">
          <div className="col-span-12">
            <div className="mb-8">
              <h3 className="text-4xl font-medium">Picking Right Project and Personas</h3>
              <p className="opacity-60 text-lg">Start by deciding on the right project and persona type that align with your goals</p>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Select Project</label>
              <select required name="project" id="project-select" onChange={handleProjectChange} value={selectedProjectId}>
                <option>Select Project</option>
                {projects.map((project) => (
                  <option key={project.id} value={project.id}>{project.project_name}</option>
                ))}
                <option value="new-project">Create New Project</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Type</label>
              <select required name="type" id="type-select" onChange={handleInputChange}>
                <option value="">Select Type</option>
                <option value="personas">Personas</option>

              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 bg-white p-8 rounded-lg mb-6 mx-auto max-w-5xl">
          <div className="col-span-6">
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Name</label>
              <input required name="name" type="text" placeholder="Enter your name" onChange={handleInputChange}/>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Gender</label>
              <select required name="gender" id="gender-select" onChange={handleInputChange}>
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Age</label>
              <input required name="age" min="10" max="120" type="number" placeholder="What's the age" onChange={handleInputChange}/>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Education:</label>
              <select required name="education" id="education-select" onChange={handleInputChange}>
                <option value="">Select Education</option>
                <option value="Early childhood education">Early childhood education</option>
                <option value="Primary education">Primary education</option>
                <option value="Upper secondary education">Upper secondary education</option>
                <option value="Bachelor’s or equivalent level">Bachelor’s or equivalent level</option>
                <option value="Master’s or equivalent level">Master’s or equivalent level</option>
                <option value="Doctoral or equivalent level">Doctoral or equivalent level</option>
                <option value="Professor">Professor</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Occupation</label>
              <input required name="occupation" type="text" placeholder="What is their occupation?" onChange={handleInputChange}/>
            </div>
          </div>
          <div className="col-span-6">
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Country</label>
              <input required name="country" type="text" placeholder="What country do they live in?" onChange={handleInputChange}/>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">City</label>
              <input required name="city" type="text" placeholder="What city do they live in?" onChange={handleInputChange}/>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Family life cycle</label>
              <select required name="familycycle" id="familycycle-select" onChange={handleInputChange}>
                <option value="">Select Family Life Cycle</option>
                <option value="Young, Single">Young, single</option>
                <option value="Young, married, no children">Young, married, no children</option>
                <option value="Young, married, the youngest child under 6">Young, married, the youngest child under 6</option>
                <option value="Young married, youngest child 6 or over">Young married, youngest child 6 or over</option>
                <option value="Older, married, no children">Older, married, no children</option>
                <option value="Older, married, children above 18">Older, married, children above 18</option>
                <option value="Older, single">Older, single</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Family Size</label>
              <input required name="familysize" type="number" max="10" placeholder="Number of people in the household" onChange={handleInputChange}/>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-slate-700">Income yearly (currency doesn't matter)</label>
              <input required name="income" type="number" minLength="0" placeholder="How much money do they earn monthly?" onChange={handleInputChange}/>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-6 bg-white p-8 rounded-lg mx-auto max-w-5xl">
          <div className="col-span-12">
            <div className="mb-3">
              <h2 className="text-xl font-semibold mb-4">Biography</h2>
              <textarea required rows="6" name="biography" minLength="20" placeholder="e.g. 'Family-oriented woman, manages her job at a local boutique and loves cooking post-work, usually purchasing via phone..." onChange={handleInputChange}></textarea>
            </div>
            <h2 className="text-xl font-semibold mb-4">Motivations</h2>
            <div className="mb-3">
              <textarea required rows="6" name="motivations" minLength="20" placeholder="e.g. motivated by projects that allow him to express his creativity, prestige..." onChange={handleInputChange}></textarea>
            </div>
            <div className="mb-3">
              <h2 className="text-xl font-semibold mb-4">Needs</h2>
              <textarea required rows="6" name="needs" minLength="20" placeholder="e.g. frustrated by slow-loading ecommerce websites, confusing navigation structure..." onChange={handleInputChange}></textarea>
            </div>
            <div className="mb-3">
              <h2 className="text-xl font-semibold mb-4">Frustrations</h2>
              <textarea required rows="6" name="frustrations" minLength="20" placeholder="e.g. needs a smooth, fast, and secure online shopping experience, easy return policy..." onChange={handleInputChange}></textarea>
            </div>
            <button type="submit" className="bg-primary-500 hover:bg-primary-700 text-white w-full py-4 px-4 rounded mt-4">
              Create Personas
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonasForm;