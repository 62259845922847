import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchHeatmapById, evaluateHeatmap, saveHeatmap } from './api';
import Snackbar from '../Atoms/snackbar';
import { ComputerDesktopIcon, DevicePhoneMobileIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Fragment } from 'react';
import { Switch } from '@headlessui/react';
import { ChatBubbleLeftIcon, LightBulbIcon, FlagIcon } from '@heroicons/react/24/outline';





const statusColors = {
  Good: 'bg-green-100 border-green-500',
  'Not Okay': 'bg-yellow-100 border-yellow-500',
  Bad: 'bg-red-100 border-red-500',
};

const categories = ["Visual Design", "Layout", "Content", "Accessibility", "Usability"];
const priorities = ["High", "Medium", "Low"];

const SingleHeatmap = () => {
  const { id } = useParams();
  const [heatmap, setHeatmap] = useState({
    name: '',
    screenshot: null,
    screenshot_mobile: null,
    coordinates_grid_screenshot: null,
    coordinates_grid_screenshot_mobile: null,
    evaluation_desktop: '',
    evaluation_mobile: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [showGrid, setShowGrid] = useState(false);
  const [viewMode, setViewMode] = useState('desktop');

  const handleSaveCategories = (newCategories) => {
    setSelectedCategories(newCategories);
  };

  const handleResetCategories = () => {
    setSelectedCategories([]);
  };

  const handleSavePriorities = (newPriorities) => {
    setSelectedPriorities(newPriorities);
  };

  const handleResetPriorities = () => {
    setSelectedPriorities([]);
  };


  const navigate = useNavigate();

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPriorities, setSelectedPriorities] = useState([]);






  useEffect(() => {
    fetchHeatmapData();
  }, [id]);

  const fetchHeatmapData = async () => {
    setLoading(true);
    try {
      const response = await fetchHeatmapById(id);
      console.log('Fetched heatmap data:', response);
      const evaluation_desktop = parseEvaluation(response.evaluation_desktop);
      const evaluation_mobile = parseEvaluation(response.evaluation_mobile);
      const screenshots = processScreenshots(response);

      setHeatmap({
        ...response,
        ...screenshots,
        evaluation_desktop,
        evaluation_mobile,
        name: response.name || response.title || `Heatmap ${id}`
      });
    } catch (error) {
      console.error('Failed to fetch heatmap details:', error);
      setError('Failed to fetch heatmap details.');
    } finally {
      setLoading(false);
    }
  };

  const parseEvaluation = (evaluation) => {
    if (typeof evaluation === 'string') {
      try {
        return JSON.parse(evaluation);
      } catch (e) {
        console.error('Error parsing evaluation:', e);
        return [];
      }
    }
    return Array.isArray(evaluation) ? evaluation : [];
  };

  const processScreenshots = (response) => {
    const supportedFormats = ['.jpeg', '.jpg', '.png', '.gif', '.webp'];
    const processUrl = (url) => {
      if (!url) return null;
      const fullUrl = `https://panel.reislo.com/assets/${url}`;
      return supportedFormats.some(format => fullUrl.endsWith(format)) ? fullUrl : `${fullUrl}.jpg`;
    };

    return {
      screenshot: processUrl(response.screenshot),
      screenshot_mobile: processUrl(response.screenshot_mobile),
      coordinates_grid_screenshot: processUrl(response.coordinates_grid_screenshot),
      coordinates_grid_screenshot_mobile: processUrl(response.coordinates_grid_screenshot_mobile),
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHeatmap((prev) => ({ ...prev, [name]: value }));
  };

  const handleEvaluate = async () => {
    setLoading(true);
    try {
      const screenshotUrl = viewMode === 'desktop' ? heatmap.coordinates_grid_screenshot : heatmap.coordinates_grid_screenshot_mobile;
      const evaluationResponse = await evaluateHeatmap({
        ...heatmap,
        screenshot: screenshotUrl,
      });
      setHeatmap((prev) => ({
        ...prev,
        [viewMode === 'desktop' ? 'evaluation_desktop' : 'evaluation_mobile']: evaluationResponse,
      }));
    } catch (error) {
      console.error('Error during evaluation:', error);
      setSnackbarMessage('Failed to evaluate heatmap');
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const goBack = () => {
    navigate('/predictions');
  };

  const handleSaveHeatmap = async () => {
    setLoading(true);
    try {
      const response = await saveHeatmap(id, {
        ...heatmap,
        evaluation_desktop: JSON.stringify(heatmap.evaluation_desktop),
        evaluation_mobile: JSON.stringify(heatmap.evaluation_mobile),
      });
      console.log('Save response:', response);
      setSnackbarMessage('Heatmap has been saved properly.');
      setSnackbarType('success');
      setShowSnackbar(true);
    } catch (error) {
      console.error('Error during save:', error.response ? error.response.data : error);
      setSnackbarMessage(`Failed to update heatmap: ${error.response ? error.response.data.message : error.message}`);
      setSnackbarType('error');
      setShowSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category]
    );
  };

  const handlePriorityChange = (priority) => {
    setSelectedPriorities(prev =>
      prev.includes(priority)
        ? prev.filter(p => p !== priority)
        : [...prev, priority]
    );
  };


  const FilterDropdown = ({ title, options, selectedOptions, onChange, onSave, onReset }) => {
    const [localSelected, setLocalSelected] = useState(selectedOptions);

    const handleChange = (option) => {
      setLocalSelected(prev =>
        prev.includes(option) ? prev.filter(item => item !== option) : [...prev, option]
      );
    };

    const handleSave = () => {
      onSave(localSelected);
    };

    const handleReset = () => {
      setLocalSelected([]);
      onReset();
    };

    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {title}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items static className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {options.map((option) => (
                <div key={option} className="px-4 py-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-primary-500 transition duration-150 ease-in-out"
                      checked={localSelected.includes(option)}
                      onChange={() => handleChange(option)}
                    />
                    <span className="ml-2 text-sm text-gray-700">{option}</span>
                  </label>
                </div>
              ))}
              <div className="border-t border-gray-100 px-4 py-2 flex justify-between">
                <button
                  onClick={handleSave}
                  className="text-sm text-primary-500 hover:text-primary-700"
                >
                  Filter
                </button>
                <button
                  onClick={handleReset}
                  className="text-sm text-neutral-600 hover:text-neutral-700"
                >
                  Reset
                </button>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderFilters = () => (
    <div className="mb-4 flex items-center space-x-4 ">
      <FilterDropdown
        title="Categories"
        options={categories}
        selectedOptions={selectedCategories}
        onChange={handleCategoryChange}
        onSave={handleSaveCategories}
        onReset={handleResetCategories}
      />
      <FilterDropdown
        title="Priorities"
        options={priorities}
        selectedOptions={selectedPriorities}
        onChange={handlePriorityChange}
        onSave={handleSavePriorities}
        onReset={handleResetPriorities}
      />
      <div className="flex items-center">
        <span className="mr-3 text-sm font-medium text-gray-900">Show Grid Screenshot</span>
        <Switch
          checked={showGrid}
          onChange={setShowGrid}
          className={`${showGrid ? 'bg-indigo-600' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
        >
          <span
            className={`${showGrid ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </div>
  );


  const renderViewModeTabs = () => (
    <div className="pt-8">
      <div className="mb-8 max-w-4xl">
        <h2 className="text-3xl font-medium">Evaluation</h2>
        <p className="text-lg">function now dynamically creates the filter dropdowns based on the available categories and priorities in the evaluation data. If there are no items for a particular filter, the dropdown won't be displayed.</p>
      </div>
      <div className="flex space-x-2 mt-4 mb-12">
        <button
          onClick={() => setViewMode('desktop')}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md ${viewMode === 'desktop' ? 'bg-primary-100 text-primary-500' : 'text-gray-500'
            }`}
        >
          <ComputerDesktopIcon className="h-6 w-6" />
          <span>Desktop</span>
        </button>
        <button
          onClick={() => setViewMode('mobile')}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md ${viewMode === 'mobile' ? 'bg-primary-100 text-primary-500' : 'text-gray-500'
            }`}
        >
          <DevicePhoneMobileIcon className="h-6 w-6" />
          <span>Mobile</span>
        </button>
      </div>
    </div>
  );


  const renderEvaluation = () => {
    const evaluation = viewMode === 'desktop' ? heatmap.evaluation : heatmap.evaluation_mobile;

    if (!evaluation) {
      return <p>No evaluation available for {viewMode}</p>;
    }

    let evaluationArray = evaluation;

    if (typeof evaluation === 'string') {
      try {
        evaluationArray = JSON.parse(evaluation);
      } catch (e) {
        console.error('Error parsing evaluation:', e);
        return <p>Error parsing evaluation data</p>;
      }
    }

    if (!Array.isArray(evaluationArray) || evaluationArray.length === 0) {
      return <p>No evaluation data available for {viewMode}</p>;
    }

    const filteredEvaluation = evaluationArray.filter(feature =>
      (selectedCategories.length === 0 || selectedCategories.includes(feature.Category)) &&
      (selectedPriorities.length === 0 || selectedPriorities.includes(feature.Priority))
    );

    const statusColors = {
      Good: 'bg-green-100 text-green-800',
      'Not Okay': 'bg-yellow-100 text-yellow-800',
      Bad: 'bg-red-100 text-red-800',
    };

    const priorityColors = {
      High: 'text-red-500',
      Medium: 'text-orange-500',
      Low: 'text-blue-500',
    };

    return (
      <div className="">
        {filteredEvaluation.map((feature, index) => (
          <div key={index} className="rounded-lg mb-4">
            <div className="mt-0 relative text-neutral-900 p-4 bg-white border border-neutral-200">
              <div className="border-b border-neutral-100 pb-6 align-middle flex">
                <span className="text-sm opacity-60 mt-2">{feature.Feature} / {feature.Category}</span>
                <div className="absolute top-3 right-3 flex gap-4">
                  <span className="flex items-center gap-1">
                    <FlagIcon className={`h-5 w-5 ${priorityColors[feature.Priority] || 'text-gray-500'}`} />
                    <span className="text-sm">{feature.Priority}</span>
                  </span>
                  <span className={`py-2 px-3 rounded text-sm ${statusColors[feature.Status] || 'bg-gray-100 text-gray-800'}`}>
                    {feature.Status}
                  </span>
                </div>
              </div>

              <div className="mt-10 flex gap-4 align-top">
                <span className="text-base bg-neutral-100 text-neutral-900 p-2 rounded-full max-h-9">
                  <ChatBubbleLeftIcon className="w-5" />
                </span>
                <p className="text-base">{feature.Observation}</p>
              </div>

              {feature.Recommendation && (
                <div className="mt-6 flex gap-4 align-top">
                  <span className="text-base bg-neutral-100 text-neutral-900 p-2 rounded-full inline-block">
                    <LightBulbIcon className="w-5" />
                  </span>
                  <p className="text-base">{feature.Recommendation}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    const screenshotUrl = viewMode === 'desktop'
      ? (showGrid ? heatmap.coordinates_grid_screenshot : heatmap.screenshot)
      : (showGrid ? heatmap.coordinates_grid_screenshot_mobile : heatmap.screenshot_mobile);

    if (!screenshotUrl) {
      return <p>No screenshot available for {viewMode}</p>;
    }

    return (
      <div className={`relative ${viewMode === 'mobile' ? 'pl-40 pr-40 pt-8' : ''}`}>
        <img src={screenshotUrl} alt={`${viewMode} screenshot`} className="w-full" />
      </div>
    );
  };

  return (
    <div>
      {showSnackbar && (
        <Snackbar
          message={snackbarMessage}
          type={snackbarType}
          onClose={() => setShowSnackbar(false)}
        />
      )}
      <div className="lg:pl-24 pt-8 lg:flex lg:justify-between bg-white pr-8">
        <div className="min-w-0 flex-1">
          {loading ? (
            <div className="animate-pulse">
              <div className="h-12 bg-gray-300 rounded w-3/4 mb-4"></div>
              <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
            </div>
          ) : (
            <>
              <div className="flex border-neutral-100 border-b">
                <div className="cursor-pointer" onClick={goBack}>
                  <div className="bg-white border border-secondary-100 hover:shadow rounded-full p-3 flex items-center justify-center">
                    <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </div>

                {heatmap.name ? (
                  <input
                    type="text"
                    name="name"
                    value={heatmap.name}
                    onChange={handleInputChange}
                    className="mx-auto max-w-xl border-none text-center text-lg"
                  />
                ) : (
                  <div className="mt-2 text-2xl font-bold leading-7 text-neutral-500 sm:truncate sm:text-3xl sm:tracking-tight">
                    Heatmap name not available
                  </div>
                )}

                <div className="flex lg:ml-4 lg:mt-0">
                  <span className="sm:ml-3">
                    <button
                      type="button"
                      onClick={handleSaveHeatmap}
                      className="inline-flex items-center rounded-md bg-primary-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                    >
                      Save
                    </button>
                  </span>
                  <span className="ml-3 sm:ml-3">
                    <button
                      type="button"
                      onClick={handleEvaluate}
                      className="inline-flex items-center rounded-md bg-secondary-500 px-3 py-2 text-sm font-medium text-white shadow-sm hover:bg-secondary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary-500"
                    >
                      Evaluate
                    </button>
                  </span>
                </div>
              </div>

              {renderViewModeTabs()}
              {renderFilters()}
            </>
          )}
        </div>
      </div>

      <div className="bg-primary-50 relative border-t border-neutral-200">
        <div className="lg:pl-24 ">
          <div className="grid grid-cols-12 mt-0 gap-4 pr-8 bg-primary-50 py-12">
            {loading ? (
              <div className="col-span-12 mt-12 bg-white rounded-xl animate-pulse">
                <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                <div className="h-28 bg-gray-300 rounded mb-4"></div>
              </div>
            ) : (
              <>
                <div className="col-span-6 mt-5">
                  {renderEvaluation()}
                </div>
                <div className="col-span-6 p-5 relative">

                  <div className="tooltip-wrapper border border-neutral-200 bg-white">
                    {renderContent()}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};



export default SingleHeatmap;