import axios from 'axios';
import { checkRunStatus, listMessages, apiHeaders } from '../articlesGpt';
import { SEO_ASSISTANT_ID } from '../../../api';
import { DIRECTUS_INSTANCE } from '../../../api'; // Make sure this import is correct


const generateArticlePrompt = (articleDraft, keywords, toneOfVoice, complexity, language) => {
  // Parse the articleDraft if it's a string
  const parsedDraft = typeof articleDraft === 'string' ? JSON.parse(articleDraft) : articleDraft;
  const outline = parsedDraft.blocks
    // Extract the outline from the parsed draft

    .filter(block => block.type === 'header' || block.type === 'list')
    .map(block => {
      if (block.type === 'header') {
        return `${block.data.level === 2 ? '##' : '###'} ${block.data.text}`;
      } else if (block.type === 'list') {
        return block.data.items.map(item => `- ${item}`).join('\n');
      }
    })
    .join('\n\n');

  const prompt = `
    You are a professional content writer tasked with creating a comprehensive, in-depth article of at least 2000 words (!). Follow these instructions carefully:
    
    1. Article Structure:
       - Use the provided outline as a guide, but expand significantly on each point.
       - Main Sections: Use H2 headings for main sections as in the draft.
       - Subsections: Use H3 headings for subsections.
       - Paragraphs: Each section should have multiple paragraphs. Aim for 3-5 paragraphs per main section.
       - Use bullet points and numbered lists where appropriate to improve readability.
    
    2. Content Depth:
       - Provide detailed explanations for each point in the outline.
       - Include relevant examples, case studies, or data to support your arguments.
       - Address potential counterarguments or alternative viewpoints.
       - Explain complex concepts in simple terms, then build upon them.
    
    3. Keyword Usage:
       - Incorporate the main keywords naturally throughout the article.
       - Aim for a keyword density of approximately 3%.
       - Make keywords bold in the text.
       - Ensure the primary keyword is in the H1 title and at least one H2 heading.
    
    4. Article Components:
       - Title (H1): Create an engaging title that includes the primary keyword.
       - Introduction (250-300 words): Hook the reader, state the problem, and provide a brief overview.
       - Body: Develop each section fully, connecting ideas logically.
       - Conclusion (200-250 words): Summarize key points and provide a call-to-action or final thought.
    
    5. Writing Style:
       - Tone of Voice: ${toneOfVoice}
       - Complexity: Aim for a Fog Index of ${complexity}
       - Use varied sentence structures to maintain reader interest.
       - Employ transition words and phrases to ensure smooth flow between paragraphs and sections.
    
    6. Engagement:
       - Ask rhetorical questions to engage the reader.
       - Use analogies or metaphors to explain complex ideas.
       - Include a mix of factual information and thought-provoking content.
    
    Remember, the goal is to create a comprehensive, valuable resource for the reader. Take your time to fully explore each topic, and don't hesitate to dive deep into the subject matter. The final article should be at least 2000 words long, providing thorough coverage of the topic.
    
    Write the article now, using markdown formatting where necessary. Focus solely on producing the content without any additional comments or explanations.
    `;

  console.log('Generated Prompt:', prompt);
  return prompt;
};

const generateArticle = async (articleId, articleDraft, keywords, toneOfVoice, complexity, language) => {
  try {
    console.log('Article Generation Parameters:', { articleId, keywords, toneOfVoice, complexity, language });


    // Fetch the article data from Directus to get the thread_id
    const articleResponse = await axios.get(`${DIRECTUS_INSTANCE}/items/articles/${articleId}`);
    const threadId = articleResponse.data.data.thread_id;

    if (!threadId) {
      throw new Error('No thread_id found for this article. Please ensure the article draft was saved correctly.');
    }

    console.log('Using existing Thread ID:', threadId);

    const draftString = typeof articleDraft === 'object' ? JSON.stringify(articleDraft, null, 2) : articleDraft;

    // Extract the outline from the articleDraft
    const parsedDraft = typeof articleDraft === 'string' ? JSON.parse(articleDraft) : articleDraft;
    const outline = parsedDraft.blocks
      .filter(block => block.type === 'header' || block.type === 'list')
      .map(block => {
        if (block.type === 'header') {
          return `${block.data.level === 2 ? '##' : '###'} ${block.data.text}`;
        } else if (block.type === 'list') {
          return block.data.items.map(item => `- ${item}`).join('\n');
        }
      })
      .join('\n\n');

    const prompt = generateArticlePrompt(draftString, keywords, toneOfVoice, complexity, language);




    const messages = [
      {
        role: 'assistant',
        content: `I am an expert content writer and my job is to write Article in ${language}`
      },
      {
        role: 'user',
        content: `Read carefully the article outline from the top of this thread. In next messages I will send you requirements`
      },
      {
        role: 'user',
        content: `Search through the files you have access and ensure that you covered all important data. On the bottom please create a bibliography of your sources mentioning the source【】and the title / filename / page of the source.`
      },
      {
        role: 'user',
        content: `2. Keywords:
  - Main keywords : ${keywords.join(', ')}
  - Put Keywords in H1 and H2`
      },
      {
        role: 'user',
        content: prompt
      }
    ];

    for (const message of messages) {
      const response = await axios.post(`https://api.openai.com/v1/threads/${threadId}/messages`, message, { headers: apiHeaders });
      console.log('Message added to thread:', response.data);
    }

    const runRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/runs`, {
      assistant_id: SEO_ASSISTANT_ID
    }, { headers: apiHeaders });
    const runId = runRes.data.id;
    console.log('Assistant run started with ID:', runId);

    let runData;
    while (true) {
      runData = await checkRunStatus(threadId, runId);
      console.log('Run status:', runData.status);
      if (runData.status === 'completed') break;
      if (runData.status === 'failed') throw new Error('Assistant run failed.');
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    const messagesRes = await listMessages(threadId);
    console.log('Messages in thread:', messagesRes);

    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const generatedContent = assistantMessage.content[0].text.value;
    console.log('Generated content (first 200 characters):', generatedContent.substring(0, 200));



    return generatedContent;
  } catch (error) {
    console.error('Error in generating article:', error);
    throw new Error(`Failed to generate article: ${error.message}`);
  }
};

export default generateArticle;