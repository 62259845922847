import React from 'react';

const FormWithResponse = ({ output, loading, error }) => {
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!output) {
    return null;
  }

  console.log('Validating response structure:', output);

  if (!output.motivations || !output.needs || !output.frustrations || !output.biography) {
    console.error('Invalid response structure:', output);
    return <div>Invalid response structure</div>;
  }

  const renderSection = (title, section) => (
    <div key={title}>
      <h3>{title}</h3>
      {section.blocks.map((block, idx) => (
        <p key={idx} dangerouslySetInnerHTML={{ __html: block.data.text }} />
      ))}
    </div>
  );

  return (
    <div>
      <h2>Generated Persona</h2>
      {Object.entries(output).map(([sectionName, sectionData]) =>
        renderSection(sectionName, sectionData)
      )}
    </div>
  );
};

export default FormWithResponse;